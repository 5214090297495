import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Interlocutor, InterlocutorDataForm, InterlocutorFilters } from '../../models/Interlocutor';

const useInterlocutorService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetInterlocutors, setFetchingGetInterlocutors] = useState(false);
    const [fetchingShowInterlocutor, setFetchingShowInterlocutor] = useState(false);
    const [fetchingStoreInterlocutor, setFetchingStoreInterlocutor] = useState(false);
    const [fetchingEditInterlocutor, setFetchingEditInterlocutor] = useState(false);
    const [fetchingUpdateInterlocutor, setFetchingUpdateInterlocutor] = useState(false);
    const [fetchingDeleteInterlocutor, setFetchingDeleteInterlocutor] = useState(false);
    const [fetchingActiveInterlocutor, setFetchingActiveInterlocutor] = useState(false);
    const [fetchingCreateInterlocutor, setFetchingCreateInterlocutor] = useState(false);

    const [fetchingGetInterlocutorsWithServiceManifest, setFetchingGetInterlocutorsWithServiceManifest] = useState(false);

    // const [FetchingChangeStatusInterlocutor, setFetchingChangeStatusInterlocutor] =
        useState(false);

    const getInterlocutors = (filters: Record<string, string | number>, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INTERLOCUTOR.GET_INTERLOCUTORS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetInterlocutors
        });
    };

    const showInterlocutor = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.SHOW_INTERLOCUTOR.replace(':id', id.toString()),
            setFetching: setFetchingShowInterlocutor
        });
    };

    const createInterlocutor = (dispatchProgram: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.CREATE_INTERLOCUTOR.replace(':id', dispatchProgram.toString()),
            setFetching: setFetchingCreateInterlocutor
        });
    };

    const storeInterlocutor = (dispatchProgram: number, interlocutor: InterlocutorDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INTERLOCUTOR.STORE_INTERLOCUTOR.replace(':id', dispatchProgram.toString()),
            body: interlocutor,
            setFetching: setFetchingStoreInterlocutor
        });
    };

    const editInterlocutor = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.EDIT_INTERLOCUTOR.replace(':id', id.toString()),
            setFetching: setFetchingEditInterlocutor
        });
    };

    const updateInterlocutor = (id: number, interlocutor: Interlocutor, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INTERLOCUTOR.UPDATE_INTERLOCUTOR.replace(':id', id.toString()),
            body: interlocutor,
            setFetching: setFetchingUpdateInterlocutor
        });
    };

    const deleteInterlocutor = (
        dispatchProgramId: number,
        interlocutorId: number,
        events: ServiceEvents = {}
    ) => {
        const url = EndPoints.INTERLOCUTOR.DELETE_INTERLOCUTOR
            .replace(':dispatch_program_id', dispatchProgramId.toString())
            .replace(':interlocutor_id', interlocutorId.toString());
    
        doDelete({
            ...events,
            url: url,
            setFetching: setFetchingDeleteInterlocutor
        });
    };

    const activeInterlocutor = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INTERLOCUTOR.ACTIVE_INTERLOCUTOR.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveInterlocutor
        });
    };

    const getInterlocutorsWithServiceManifest = (
            filter: InterlocutorFilters,
            dispatch_program_id: number,
            events: ServiceEvents = {}
        ) => {
            const queryString = Object.entries(filter)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');
    
            const url = `${EndPoints.DISPATCH_PROGRAMS.INTERLOCUTOR_SERVICE_MANIFEST}?${queryString}`;
         
            doGet({
                ...events,
                url: url.replace(':dispatch_program_id', dispatch_program_id.toString()),
                setFetching: setFetchingGetInterlocutorsWithServiceManifest
            });
        };

    // const changeToStatusInterlocutor = (id: number, status: string, events: ServiceEvents = {}) => {
    //     doPut({
    //         ...events,
    //         url: EndPoints.DISPATCH_PROGRAMS.CHANGE_TO_STATUS_INTERLOCUTOR.replace(':id', id.toString()),
    //         body: { status }, // Asegúrate de enviar un objeto JSON válido
    //         headers: {
    //             'Content-Type': 'application/json', // Agregar encabezado explícito
    //         },
    //         setFetching: setFetchingChangeStatusInterlocutor,
    //     });
    // };

    return {
        fetchingGetInterlocutors,
        fetchingShowInterlocutor,
        fetchingStoreInterlocutor,
        fetchingEditInterlocutor,
        fetchingUpdateInterlocutor,
        fetchingDeleteInterlocutor,
        fetchingActiveInterlocutor,
        fetchingCreateInterlocutor,
        fetchingGetInterlocutorsWithServiceManifest,
        getInterlocutorsWithServiceManifest,
        // FetchingChangeStatusInterlocutor,
        // changeToStatusInterlocutor,
        createInterlocutor,
        getInterlocutors,
        showInterlocutor,
        storeInterlocutor,
        editInterlocutor,
        updateInterlocutor,
        deleteInterlocutor,
        activeInterlocutor
    };
};

export default useInterlocutorService;
