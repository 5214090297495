
import { AccountingStatus } from './Accounting';
import { DailyCarrier } from './DailyCarrier';
import { Guide } from './DispatchProgramItem';
import { PurchaseOrderDetail } from './PurchaseOrderDetail';
import { ServiceManifest } from './ServiceManifest';
import { Tax } from './Tax';

export interface ServiceManifestDetail {
    id?: number;
    service_manifest?: ServiceManifest;
    service_manifest_id?: number;
    position?: number;
    purchase_order_detail?: PurchaseOrderDetail;
    purchase_order_detail_id?: number;
    quantity?: number;
    created_at?: string;
    
}

export interface ServiceManifestDetailDataForm {
    id?: number;
    product_id?: number;
    product?: string;
    service_id?: number;
    service?: string;
    service_manifest?: string;
    service_manifest_id?: number;
    position?: number;
    purchase_order_id?: number;
    purchase_order_detail?: string;
    purchase_order_detail_id?: number;
    quantity?: number;

    quantity_purchase_order_detail?: number;
    suggested_quantity?: number;
    currency_price_id?: number;
    currency_price?: string;
    unit_price_id?: number;
    unit_price?: string;
    unit_id?: number;
    unit?: string;
    created_at?: string;
    item?: string;
    item_type?: string;
    service_type?: string;
    price?: number;
    cost_center?: string;
    gross_total?: number;
    taxes?: number;
    tax_total?: number;
    json_tax?: Tax[];
    item_name?: string;
    available_quantity?: number;
    total_batch_quantity_calculated?: number;
    status_obj?: GetStatus;
    status_text?: string;
    service_manifest_status?: string;
    batch_id?: number;
    society?: string;
    provider?: string;
    batch?: string;
    
    plan_quantity?: number;
    dispatch_date?: string;
    carrier_id?: number;
    carrier?: string;
    truck_id?: number;
    truck?: string;
    provider_id?: number;
    hopper_id?: number;
    hopper?: string;
    total?: number;
    folio?: string;
    tara_total?: number;
    actual_dispatch_date?: string;
    file?: File;
    file_url?: string;
}

export const defaultServiceManifestDetailDataForm: ServiceManifestDetailDataForm = {
    product: '',
    service: '',
    currency_price: '',
    unit_price: '',

    unit: '',
};

export interface serviceManifestDetailFilters {
    id?: number | null;
    item?: string | '';
    item_type?: string | '';
    created_at_from?: string | '';
    created_at_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultDispatchProgramItemResetData = {
    plan_quantity: undefined,
    dispatch_date: undefined,
    carrier_id: undefined,
    provider_id: undefined,
    truck_id: undefined,
    hopper_id: undefined,
    total: undefined,
    folio: undefined, 
    tara_total: undefined,
    actual_dispatch_date: undefined,
    file: undefined
}

interface GetStatus {
    status?: AccountingStatus;
    status_text?: string;
}
