import React from 'react';
import ProviderForm from './ProviderForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Provider } from '../../../app/models/Provider';
import { Society } from '../../../app/models/Society';
import { SocietyProviderDataForm } from '../../../app/models/SocietyProvider';
import { ProviderContact } from '../../../app/models/ProviderContact';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    providers: Provider[];
    societies: Society[];
    providerTypes?: any[];
    societyProviderForm: SocietyProviderDataForm;
    setSocietyProviderForm: (societyproviderForm: SocietyProviderDataForm) => void;
    providerContacts: ProviderContact[];
    loadingProviderContact: boolean;
    errorFields?: any;
    isEdit?: boolean

}

const ProviderFormContainer = ({
    fetching,
    action,
    cancel,
    providers,
    societies,
    providerTypes,
    societyProviderForm,
    setSocietyProviderForm,
    providerContacts,
    loadingProviderContact,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProviderForm
                        providers={providers}
                        providerTypes={providerTypes}
                        societies={societies}
                        societyProviderForm = {societyProviderForm}
                        setSocietyProviderForm={setSocietyProviderForm}
                        providerContacts={providerContacts}
                        loadingProviderContact={loadingProviderContact}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProviderFormContainer;
