import React, { useContext, useEffect, useState } from 'react';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import QuoteDetailsDataTable from './tables/QuoteDetailsDataTable';
import {
    TransportationQuoteDetail,
    TransportationQuoteDetailFilters
} from '../../../app/models/TransportationQuoteDetail';
import useTransportationQuoteDetailService from '../../../app/services/hooks/useTransportationQuoteDetailService';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import QuoteDetailCreate from './QuoteDetailCreate';
import QuoteDetailEdit from './QuoteDetailEdit';
import QuoteDetailShowForm from './forms/QuoteDetailShowForm';
import { QuoteDataForm, TransportationQuoteDetailShowDataForm } from '../../../app/models/Quote';
import { Roles } from '../../../app/shared/enums';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AuthContext } from '../../../contexts/AuthContext';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';

interface Props {
    quoteId: number;
    currencyId: number | string;
    quoteForm: QuoteDataForm;
    setQuoteForm?: (quoteForm: QuoteDataForm) => void;
    setQuoteRouteSegmentTable?: (quoteRouteSegments: QuoteRouteSegment[]) => void;
    reloadQuoteRouteSegmentTable: () => void;
    reloadQuote: (quoteId: number) => void;
    disableUpdate?: boolean;
}

const QuoteDetailsContainer = ({
    quoteId,
    currencyId,
    quoteForm,
    setQuoteForm,
    setQuoteRouteSegmentTable,
    reloadQuoteRouteSegmentTable,
    reloadQuote,
    disableUpdate = false
}: Props) => {
    const {
        getAllTransportationQuoteDetailsByRequest,
        fetchingGetTransportationQuoteDetails,
        deleteTransportationQuoteDetail,
        showTransportationQuoteDetail
    } = useTransportationQuoteDetailService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [showingCreate, setShowingCreate] = useState(false);
    const [quoteDetailIdEdit, setQuoteDetailIdEdit] = useState<number>(-1);
    const [quoteDetailShow, setDetailShow] = useState<TransportationQuoteDetailShowDataForm>({});
    const { auth } = useContext(AuthContext);
    const [quoteDetails, setQuoteDetails] = useState<TransportationQuoteDetail[]>([]);
    const [filter, setFilter] = useState<TransportationQuoteDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        if (quoteId) {
            reloadTable();
        }
    }, [quoteId]);

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const showEdit = (quoteDetailId: number) => {
        setShowingEdit(true);
        setQuoteDetailIdEdit(quoteDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setQuoteDetailIdEdit(-1);
    };

    const hideShow = () => {
        setShowingShow(false);
    };

    const show = (quoteDetailId: number) => {
        showTransportationQuoteDetail(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setDetailShow(response.data.transportation_quote_detail);
                setShowingShow(true);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const destroy = (quoteDetailId: number) => {
        const _text = 'Está a punto de eliminar el detalle de la cotización #' + quoteDetailId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando detalle cotización...', 400);
                deleteTransportationQuoteDetail(quoteDetailId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el detalle de la cotización #' + quoteDetailId
                        });
                        reloadTable();
                        reloadQuote(quoteId);
                        reloadQuoteRouteSegmentTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El detalle de la cotización no se ha eliminado.'
                });
            }
        });
    };

    const reloadTable = () => {
        getAllTransportationQuoteDetailsByRequest(quoteId, filter, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteDetails(response.data.transportation_quote_details);
                setTotalRows(response.data.total_rows);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <DefaultToolBar
                            left={<ButtonCreate callbackCreate={showCreate} />}
                        />
                    </div>
                    <div className="col-12">
                        <QuoteDetailsDataTable
                            quoteDetails={quoteDetails}
                            totalRows={totalRows}
                            show={show}
                            edit={showEdit}
                            destroy={destroy}
                            filter={filter}
                            setFilter={setFilter}
                            loading={fetchingGetTransportationQuoteDetails}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Detalle Cotización"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteDetailCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        quoteId={quoteId}
                        quoteForm={quoteForm}
                        setQuoteForm={setQuoteForm!}
                        currencyId={currencyId}
                        setQuoteRouteSegmentTable={setQuoteRouteSegmentTable!}
                        reloadQuoteRouteSegmentTable={reloadQuoteRouteSegmentTable}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Detalle Cotización"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteDetailEdit
                        quoteId={quoteId}
                        quoteForm={quoteForm}
                        setQuoteForm={setQuoteForm!}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onError={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        quoteDetailId={quoteDetailIdEdit}
                        setQuoteRouteSegmentTable={setQuoteRouteSegmentTable!}
                        reloadQuoteRouteSegmentTable={reloadQuoteRouteSegmentTable}
                    />
                </DefaultModal>
            ) : null}
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Editar Detalle Cotización"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteDetailShowForm quoteRequestDetail={quoteDetailShow} />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default QuoteDetailsContainer;
