import React, { useContext, useEffect, useRef, useState } from 'react';

import { defaultProvider, Provider } from '../../../app/models/Provider';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ProviderFormContainer from './ProviderFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Society } from '../../../app/models/Society';
import { BusinessName } from '../../../app/models/BusinessName';
import useSocietyProviderService from '../../../app/services/hooks/useSocietyProviderService';
import { SocietyProviderDataForm } from '../../../app/models/SocietyProvider';
import { ProviderContact } from '../../../app/models/ProviderContact';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreProvider, storeProvider, createProvider, fetchingCreateProvider } =
        useProviderService();
    // const [provider, setProvider] = useState<Provider>(defaultProvider);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [businessName, setBusinessName] = useState<BusinessName[]>([]);
    const [ProviderContacts, setProviderContacts] = useState<ProviderContact[]>([]);

    const [providerTypes, setProviderTypes] = useState([]);
    const [errorFields, setErrorFields] = useState<any>();

    const [societyProvider, setSocietyProvider] = useState<SocietyProviderDataForm>(
        {
            society_id: 0,
            provider_id: 0,
            account_number: ''
        }
    )
    const providerIdSelected = useRef<number | undefined>(undefined);


    const {
        storeSocietyProvider,
        // getProviderContact, 
        fetchingStoreSocietyProvider,
    } = useSocietyProviderService();

    const {
        fetchingGetBusinessNameContactsByProvider,
        getBusinessNameContactByProvider
    } = useBusinessNameContactService()

    useEffect(() => {
        createProvider({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies)
                setProviders(response.data.providers)
             
                // const providersWithAccountNumber = response.data.providers.map((provider: Provider) => {
                //     return {
                //       ...provider,
                //       account_number: `${provider.society_id}-${provider.business_name_id}`
                //     };
                //   });
                  
                // setProviders(providersWithAccountNumber);
           
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Agregando proveedor...');

        storeSocietyProvider(societyProvider, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    // useEffect(() => {
    //     if (societyProvider.provider_id) {
    //         const selectedProvider = providers.find(
    //             (p) => p.id === societyProvider.provider_id
    //         );
    
    //         if (selectedProvider) {
    //             setProvider({
    //                 ...provider,
    //                 rut: selectedProvider.rut,
    //                 name: selectedProvider.name,
    //                 email:selectedProvider.email,
    //                 phone_code: selectedProvider.phone_code,
    //                 phone: selectedProvider.phone,
    //                 is_national:selectedProvider.is_national,
    //             });
         
    //         }
    //     }
    // }, [societyProvider.provider_id, providers]);

    useEffect(() => {

        if (societyProvider.society_id && societyProvider.provider_id && providers.length > 0) {
            const selectedProvider = providers.find(p => p.id === Number(societyProvider.provider_id));
    
            if (selectedProvider) {
                const accountNumber = `${societyProvider.society_id}-${selectedProvider.business_name_id}`;
                setSocietyProvider((prev) => ({
                    ...prev,
                    account_number: accountNumber,
                }));
            }
        }
    }, [societyProvider.society_id, societyProvider.provider_id]);

     

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateProvider ? (
        <LazyLoading height={300} />
    ) : (
        <ProviderFormContainer
            fetching={fetchingStoreProvider}
            action={store}
            cancel={cancel}
            providerTypes={providerTypes}
            providers={providers}
            providerContacts={ProviderContacts}
            loadingProviderContact={fetchingGetBusinessNameContactsByProvider}
            societies={societies}
            societyProviderForm={societyProvider}
            setSocietyProviderForm={setSocietyProvider}
            errorFields={errorFields}
            isEdit={false}
        />
    );
};

export default ProviderCreate;
