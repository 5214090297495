import React, { useContext } from 'react';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import { PurchaseOrderDetailDataForm } from '../../../../app/models/PurchaseOrderDetail';
import { PurchaseOrderFilters } from '../../../../app/models/PurchaseOrder';
import PurchaseOrderDetailFilter from './PurchaseOrderDetailFilter';
import { createCenteredHeader } from '../../../../components/styles/HeaderDataTable';

interface Props {
    purchaseOrderDetails: PurchaseOrderDetailDataForm[];
    totalRows: number;
    edit?: (purchaseOrderDetailId: number, position: number) => void;
    destroy?: (purchaseOrderDetailId: number, position: number) => void;
    show?: (purchaseOrderDetailId: number, position: number) => void;
    filter: PurchaseOrderFilters;
    setFilter: (filter: PurchaseOrderFilters) => void;
    loading: boolean;
    isShow?: boolean;
}

const PurchaseOrderDetailDataTable = ({
    purchaseOrderDetails,
    edit,
    destroy,
    show,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    isShow = false
}: Props) => {

    console.log('purchaseOrderDetails:', purchaseOrderDetails);


    const columns = [
        // {
        //     name: 'id',
        //     selector: (row: PurchaseOrderDetailDataForm) => row?.id,
        //     sortable: true,
        //     sortField: 'id'
        // },
        {
            name: 'Posición',
            selector: (row: PurchaseOrderDetailDataForm) => row?.position,
            sortable: true,
            sortField: 'position'
        },
        {
            name: 'Glosa',
            selector: (row: any) => row?.item,
            sortable: true,
            sortField: 'item_name',
            minWidth: '120px',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.item_name}
                </span>
            )
        },
        {
            name: createCenteredHeader('Tipo de item'),
            selector: (row: any) => row?.item_type,
            sortable: true,
            sortField: 'item_type',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.item_type}
                </span>
            )
        },
        {
            name: 'Centro de Costo',
            selector: (row: any) => row?.cost_center?.name,
            sortable: true,
            sortField: 'cost_center_name',
            minWidth: '120px',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.cost_center?.name}
                </span>
            )
        },
        {
            name: 'Precio unitario',
            selector: (row: any) => row?.price,
            sortable: true,
            sortField: 'rate',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row.price !== null &&
                            `${formatMoney(row.price ?? 0, row.currency_price)} ${
                                row.currency_price
                            } / ${row.unit_price}`}
                    </span>
                </>
            )
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            width: '110px',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.quantity??0)} {row.unit}
                </span>
            )
        },

        {
            name: 'Precio neto',
            selector: (row: any) => row?.gross_total,
            sortable: false,
            sortField: 'gross_total',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.gross_total??0)}
                </span>
            )
        },
        {
            name: 'Impuestos',
            selector: (row: any) => row?.taxes,
            sortable: false,
            sortField: 'taxes',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.taxes??0)}
                </span>
            )
        },

        {
            name: 'Precio total',
            selector: (row: any) => row?.tax_total,
            sortable: false,
            sortField: 'tax_total',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.tax_total??0)}
                </span>
            )
        },

        {
            name: 'Fecha de entrega',
            selector: (row: any) => row?.delivery_date,
            sortable: true,
            sortField: 'delivery_date',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.delivery_date)}</span>
            )
        },

        {
            name: 'creado',
            selector: (row: any) => row?.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.created_at)}</span>
            )
        },

        
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: PurchaseOrderDetailDataForm, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro'}
                            title={'Eliminar'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <PurchaseOrderDetailFilter filter={filter} setFilter={setFilter} />
                </div>
              
            </div>

            <DefaultDataTable
                columns={columns}
                data={purchaseOrderDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default PurchaseOrderDetailDataTable;
