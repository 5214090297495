import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { Items } from '../../../app/shared/enums';
import useServiceManifestDetailService from '../../../app/services/hooks/useServiceManifestDetailService';
import {
    ServiceManifestDetailDataForm,
    defaultDispatchProgramItemResetData,
    defaultServiceManifestDetailDataForm
} from '../../../app/models/ServiceManifestDetail';
import ServiceManifestDetailForm from './forms/ServiceManifestDetailForm';
import { PurchaseOrderDetail } from '../../../app/models/PurchaseOrderDetail';
import { Batch } from '../../../app/models/Batch';
import { Provider } from '../../../app/models/Provider';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Carrier } from '../../../app/models/Carrier';

interface Props {
    serviceManifestDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestDetailEdit = ({
    serviceManifestDetailId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditServiceManifestDetail,
        editServiceManifestDetail,
        fetchingUpdateServiceManifestDetail,
        updateServiceManifestDetail
    } = useServiceManifestDetailService();

    const { fetchingGetPurchaseOrderDetail, getPurchaseOrderDetail } =
        usePurchaseOrderDetailService();

    const [serviceManifestDetail, setServiceManifestDetail] =
        useState<ServiceManifestDetailDataForm>({
            ...defaultServiceManifestDetailDataForm
        });

    const [purchaseOderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetail[]>([]);

    const [itemType, setItemType] = useState<string>('');
    const purchaseOrderDetailIdSelected = useRef<number | undefined>(0);
    const purchaseOrderRef = useRef<number>(0);

    const firstPurchaseOrderDetailId = useRef<number>(0);
    const [errorFields, setErrorFields] = useState<any>();

    const [batches, setBatches] = useState<Batch[]>([]);
    const [resultValue, setResultValue] = useState<number>(0);
    const referenceQuantity = useRef<number>(0);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHopper] = useState<Hopper[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando posición de hoja de entrada...');
        editServiceManifestDetail(serviceManifestDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setPurchaseOrderDetails(response.data.purchase_order_details);
                setProviders(response.data.providers);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHopper(response.data.hoppers);

                purchaseOrderRef.current = response.data.purchase_order_id;
                purchaseOrderDetailIdSelected.current =
                    response.data.service_manifest_detail.purchase_order_detail_id;
                firstPurchaseOrderDetailId.current =
                    response.data.service_manifest_detail.purchase_order_detail_id;

                setItemType(response.data.service_manifest_detail.item_type);
                setBatches(response.data.batches);
                setResultValue(response.data.service_manifest_detail.suggested_quantity);
                referenceQuantity.current = response.data.service_manifest_detail.quantity;

                setServiceManifestDetail(response.data.service_manifest_detail);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (serviceManifestDetail.purchase_order_detail_id == undefined) {
            purchaseOrderDetailIdSelected.current = undefined;
        }

        if (
            serviceManifestDetail.purchase_order_detail_id &&
            serviceManifestDetail.purchase_order_detail_id != purchaseOrderDetailIdSelected.current
        ) {
            getPurchaseOrderDetail(
                serviceManifestDetail.purchase_order_detail_id,
                serviceManifestDetailId,
                {
                    onSuccess: (response: ServiceResponse) => {
                        purchaseOrderDetailIdSelected.current =
                            serviceManifestDetail.purchase_order_detail_id ?? 0;
                        setErrorFields(undefined);
                        
                        if (response.data.purchase_order_detail.item_type == Items.PRODUCT) {
                            setServiceManifestDetail({
                                ...serviceManifestDetail,
                                item_name: response.data.purchase_order_detail.item_name,
                                price: response.data.purchase_order_detail.price,
                                cost_center: response.data.purchase_order_detail.cost_center,
                                currency_price: response.data.purchase_order_detail.currency_price,
                                unit_price: response.data.purchase_order_detail.unit_price,
                                unit: response.data.purchase_order_detail.unit,
                                json_tax: response.data.purchase_order_detail.json_tax,
                                quantity: response.data.purchase_order_detail.quantity,
                                suggested_quantity:
                                    response.data.purchase_order_detail.suggested_quantity,
                                batch_id: response.data.purchase_order_detail.batch_id,
                                quantity_purchase_order_detail: 
                                response.data.purchase_order_detail
                                        .quantity_purchase_order_detail,
                                service_type: undefined,
                                ...defaultDispatchProgramItemResetData
                            });
                            setBatches(response.data.batches);
                            setItemType(response.data.purchase_order_detail.item_type);
                        } else {
                            setServiceManifestDetail({
                                ...serviceManifestDetail,
                                item_name: response.data.purchase_order_detail.item_name,
                                price: response.data.purchase_order_detail.price,
                                currency_price: response.data.purchase_order_detail.currency_price,
                                unit_price: response.data.purchase_order_detail.unit_price,
                                unit: response.data.purchase_order_detail.unit,
                                json_tax: response.data.purchase_order_detail.json_tax,
                                quantity: response.data.purchase_order_detail.quantity,
                                suggested_quantity: response.data.purchase_order_detail.suggested_quantity,
                                batch_id: 0,
                                quantity_purchase_order_detail: 
                                response.data.purchase_order_detail
                                        .quantity_purchase_order_detail,
                                service_type: response.data.purchase_order_detail.service_type ?? undefined,
                                ...defaultDispatchProgramItemResetData,
                                ...response.data.extra_data ?? {}
                            });
                            setItemType(response.data.purchase_order_detail.item_type);
                        }
                        setResultValue(response.data.purchase_order_detail.suggested_quantity);
                        referenceQuantity.current = response.data.purchase_order_detail.quantity;
                    },
                    onError: (response: ServiceResponse) => {
                        setServiceManifestDetail({
                            ...serviceManifestDetail,
                            purchase_order_detail_id: purchaseOrderDetailIdSelected.current
                        });
                        toast.error(response.message);
                    }
                }
            );
        }
    }, [serviceManifestDetail.purchase_order_detail_id]);

    const update = () => {
        
        if (showLoading) showLoading('loading', 'Actualizando posición de hoja de entrada...');
        updateServiceManifestDetail(serviceManifestDetailId, serviceManifestDetail, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const setReferenceQuantity = (value: number) => {
        referenceQuantity.current = value;
    }

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditServiceManifestDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ServiceManifestDetailForm
                        serviceManifestDetailDataForm={serviceManifestDetail}
                        setServiceManifestDetailDataForm={setServiceManifestDetail}
                        purchaseOrderDetails={purchaseOderDetails}
                        itemType={itemType}
                        batches={batches}
                        purchaseOrderRef={purchaseOrderRef.current}
                        errorFields={errorFields}
                        itemLoading={fetchingGetPurchaseOrderDetail}
                        resultValue={resultValue}
                        setResultValue={setResultValue}
                        referenceQuantity={referenceQuantity.current}
                        setReferenceQuantity={setReferenceQuantity}
                        providers={providers}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateServiceManifestDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateServiceManifestDetail}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceManifestDetailEdit;
