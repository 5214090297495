import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useCostCenterService from '../../../app/services/hooks/useCostCenterService';
import { CostCenterDataForm } from '../../../app/models/CostCenter';
import CostCenterFormContainer from './CostCenterFormContainer';


interface Props {
    costCenterId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CostCenterEdit = ({ costCenterId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditCostCenter, editCostCenter, fetchingUpdateCostCenter, updateCostCenter } =
        useCostCenterService();

    const [costCenter, setCostCenter] = useState<CostCenterDataForm>({ name: '', description:''});
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando centro de costo...');
        editCostCenter(costCenterId, {
            onSuccess: (response: ServiceResponse) => {
                setCostCenter(response.data.cost_center);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando centro de costo...');
        updateCostCenter(costCenterId, costCenter, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCostCenter ? (
        <LazyLoading height="300" />
    ) : (
        <CostCenterFormContainer
            fetching={fetchingUpdateCostCenter}
            action={update}
            cancel={cancel}
            costCenter={costCenter}
            setCostCenter={setCostCenter}
            errorFields={errorFields}
        />
    );
};

export default CostCenterEdit;
