import React, { useContext, useEffect, useRef, useState } from 'react';
import { Client, defaultClient } from '../../../app/models/Client';
import useClientService from '../../../app/services/hooks/useClientService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ClientFormContainer from './ClientFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import { Society } from '../../../app/models/Society';
import { SocietyClientDataForm } from '../../../app/models/SocietyClient';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';
import { ClientContact } from '../../../app/models/ClientContact';
import useSocietyClientService from '../../../app/services/hooks/useSocietyClientService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ClientCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const { fetchingStoreClient,fetchingCreateClient,createClient ,storeClient } = useClientService();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]); // Lista de clientes


    const [societyClient, setSocietyClient] = useState<SocietyClientDataForm>(
        {
            society_id: 0,
            client_id: 0,
            account_number: ''
        }
    )

    const [clientContacts, setClientContacts] = useState<ClientContact[]>([]);
    
    const [errorFields, setErrorFields] = useState<any>();
    const {
        storeSocietyClient,
        // getProviderContact, 
        fetchingStoreSocietyClient,
    } = useSocietyClientService();

    const {
        fetchingGetBusinessNameContactsByClient,
        getBusinessNameContactByClient
    } = useBusinessNameContactService()

    const clientIdSelected = useRef<number | undefined>(0);
    


    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createClient({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                 setLoading(false);
                 setSocieties(response.data.societies)
                 setClients(response.data.clients); 
              

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Agregando cliente...');
        storeSocietyClient(societyClient, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
               
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

  
      useEffect(() => {
  
      
          if (societyClient.society_id && societyClient.client_id && clients.length > 0) {
              const selectedClient = clients.find(p => p.id === Number(societyClient.client_id));
      
              if (selectedClient) {
                  const accountNumber = `${societyClient.society_id}-${selectedClient.business_name_id}`;
                  setSocietyClient((prev) => ({
                      ...prev,
                      account_number: accountNumber,
                  }));
              }
          }
      }, [societyClient.society_id, societyClient.client_id]);

    

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return loading ? (
        <LazyLoading height={300} />
    ) : (
        <ClientFormContainer
            fetching={fetchingStoreClient}
            action={store}
            cancel={cancel}
            societies={societies}
            societyClientForm={societyClient}
            setSocietyClientForm={setSocietyClient}
            clientContacts={clientContacts}
            clients={clients}
            loadingClientContact={fetchingGetBusinessNameContactsByClient}
            errorFields={errorFields}
            isEdit={false}

        />
    );
};

export default ClientCreate;
