import { Provider } from "./Provider";
import { Service } from "./Service";


export interface Interlocutor {
    id?: number;
    provider_rut: string;
    provider?: Provider; // Opcional para manejar casos donde no esté definido
    service?: Service;
    // status: {
    //     id: string; // El identificador del estado actual
    //     name: string; // El nombre del estado actual
    // };
    status: string;
    // status_choices?: {
    //     id: string;
    //     name: string;
    // }[]; // Opciones disponibles para cambiar el estado
}


export interface InterlocutorDataForm {
    service?: Service;
    service_id?: number | string;
    provider?: Provider;
    provider_id?: number | string;

}

export interface InterlocutorFilters {
    dispatch_program_id?: number | null;
    is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}


export const defaultInterlocutor: InterlocutorDataForm = {
    service_id: 0,
    provider_id: 0
};

export interface InterlocutorStatus {
    value: string;
    label: string;
}

export interface StatusRows {
    value: string;
    label: string;
}

export const interlocutorStatuses: InterlocutorStatus[] = [
    {
        value: 'SIN_HE_EMITIDA',
        label: 'Sin H.E. emitida'
    },
    {
        value: 'HE_EMITIDA',
        label: 'H.E. emitida'
    },
    {
        value: 'FACTURADO',
        label: 'Facturado'
    },
    {
        value: 'PAGADO',
        label: 'Pagado'
    }
];

export interface InterlocutorStatusRequestDataForm {
    id?: number | string;
    name?: number | string;
}
