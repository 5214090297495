import { Interlocutor, InterlocutorFilters } from '../../app/models/Interlocutor';
import ButtonTableAction from '../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../components/default/DefaultDataTable';
import ProviderFilter from '../Providers/components/ProviderFilter';

interface Props {
    interlocutors: Interlocutor[];
    totalRows: number;
    loading?: boolean;
    edit?: (interlocutorId: number) => void;
    show?: (interlocutorId: number) => void;
    destroy?: (interlocutorId: number) => void;
    filter: InterlocutorFilters;
    setFilter: (filter: InterlocutorFilters) => void;
    showServiceManifest?: (dailyCarrierId: number) => void;
    paginationRowsPerPageOptions?: any[];
}

const InterlocutorOnDispatchProgramDataTable = ({
    interlocutors,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    showServiceManifest
}: Props) => {
    const columns = [
        {
            name: 'ID Interlocutor',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Interlocutor, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
       
        {
            name: 'Rut Proveedor',
            selector: (row: any) => row.provider_rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: Interlocutor, index: any, column: any) => (
                <span className="">
                    {row.provider_rut}
                </span>
            )
        },
        {
            name: 'Nombre Proveedor',
            selector: (row: Interlocutor) => row.provider?.name || 'Sin Nombre Proveedor',
            sortable: true,
            sortField: 'provider.name',
            cell: (row: Interlocutor, index: any, column: any) => (
                <span className="">
                    {row.provider?.name || 'Sin Nombre'}
                </span>
            )
        },
        {
            name: 'Nombre Servicio',
            selector: (row: Interlocutor) => row.service?.name || 'Sin Servicio',
            sortable: true,
            sortField: 'service.name',
            cell: (row: Interlocutor, index: any, column: any) => (
                <span className="">
                    {row.service?.name || 'Sin Servicio'}
                </span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Interlocutor, index: any, column: any) => (
                <div className="">
                    
                    {showServiceManifest && (
                        <ButtonTableAction
                            callbackFunction={() => showServiceManifest(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Ir a hoja de entrada'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <ProviderFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={interlocutors}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default InterlocutorOnDispatchProgramDataTable;
