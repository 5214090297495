import React, { useContext, useEffect, useState } from 'react';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import QuoteRouteSegmentDataTable from './tables/QuoteRouteSegmentDataTable';
import { CheckQuoteDocumentRoutes } from '../../../app/models/Quote';
import { Roles } from '../../../app/shared/enums';
import { AuthContext } from '../../../contexts/AuthContext';
import {
    QuoteRouteSegment,
    QuoteRouteSegmentShowDataForm,
    QuoteRouteSegmentFilters
} from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import QuoteRouteSegmentCreate from '../../RouteSegment/components/QuoteRouteSegmentCreate';
import QuoteRouteSegmentEdit from '../../RouteSegment/components/QuoteRouteSegmentEdit';
import QuoteRouteSegmentShowForm from '../../RouteSegment/components/QuoteRouteSegmentShowForm';
import useQuoteService from '../../../app/services/hooks/useQuoteService';

interface Props {
    quoteId: number;
    quoteRouteSegments: QuoteRouteSegment[];
    setQuoteRouteSegments: (quoteRouteSegments: QuoteRouteSegment[]) => void;
    isFormShow?: boolean;
    quoteCheckDocument: boolean;

    // fetchingGetQuoteRouteSegments?:boolean;
    // getQuoteRouteSegments?:(quoteId:number, events: ServiceEvents) => void;
}

const QuoteRouteSegmentsContainer = ({
    quoteId,
    quoteRouteSegments,
    setQuoteRouteSegments,
    isFormShow = false,
    quoteCheckDocument
}: Props) => {
    const { getQuoteRouteSegments, deleteQuoteRouteSegment, showQuoteRouteSegment } =
        useQuoteRouteSegmentService();

    const { changeDocumentCheckRoutes } = useQuoteService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [quoteRouteSegmentShow, setQuoteRouteSegmentShow] =
        useState<QuoteRouteSegmentShowDataForm>({});

    const { auth } = useContext(AuthContext);
    const [quoteRouteSegmentIdEdit, setQuoteRouteSegmentIdEdit] = useState<number>(-1);

    const [checkboxDocument, setCheckboxDocument] = useState<boolean>(quoteCheckDocument);
    const [filter, setFilter] = useState<QuoteRouteSegmentFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        if (quoteId) {
            reloadTable();
        }
    }, [quoteId]);

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const showEdit = (quoteDetailId: number) => {
        setShowingEdit(true);
        setQuoteRouteSegmentIdEdit(quoteDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setQuoteRouteSegmentIdEdit(-1);
    };

    const hideShow = () => {
        setShowingShow(false);
    };

    const show = (quoteDetailId: number) => {
        showQuoteRouteSegment(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteRouteSegmentShow(response.data.quote_route_segment);
                setShowingShow(true);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _select = event.target.checked;

        useSweetAlert().spinnerAlert('Cambiando check...');
        changeDocumentCheckRoutes(quoteId, _select, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setCheckboxDocument(_select);
                toast.success(response.message);
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                setCheckboxDocument(!_select);
            }
        });
    };

    const onError = () => {
        setShowingEdit(false);
        setQuoteRouteSegmentIdEdit(-1);
    };

    const destroy = (quoteRouteSegmentId: number) => {
        const _text = 'Está a punto de eliminar la opción de ruta #' + quoteRouteSegmentId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando opción...');
                deleteQuoteRouteSegment(quoteRouteSegmentId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: response.message
                        });
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'La opción de ruta no se ha eliminado.'
                });
            }
        });
    };

    const reloadTable = () => {
        getQuoteRouteSegments(quoteId, filter, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteRouteSegments(response.data.quote_route_segments);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Rutas</div>
                <div className="row">
                    {!isFormShow ? (
                        <>
                            <div className="row">
                                <div className="col-12 mb-1">
                                    {auth?.roles &&
                                        (auth?.roles.includes(Roles.OPERATIONS_MANAGER) ||
                                            auth.roles.includes(Roles.MANAGERS)) && (
                                            <DefaultToolBar
                                                left={<ButtonCreate callbackCreate={showCreate} />}
                                            />
                                        )}
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxDocument}
                                        id="routes-document"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="routes-document">
                                        Incluir en documento
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <QuoteRouteSegmentDataTable
                                    quoteRouteSegments={quoteRouteSegments}
                                    totalRows={totalRows}
                                    show={show}
                                    edit={showEdit}
                                    destroy={destroy}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-12 mb-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxDocument}
                                        id="routes-document"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="routes-document">
                                        Incluir en documento
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <QuoteRouteSegmentDataTable
                                    quoteRouteSegments={quoteRouteSegments}
                                    totalRows={totalRows}
                                    show={show}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </div>
                        </>
                    )}
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear opción de ruta"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentCreate
                        onSaved={() => {
                            // toast.success('opción de tramo Creado');
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        quoteId={quoteId}
                        // currencyId={currencyId}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar opción de tramo"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentEdit
                        quoteRouteSegmentIdEdit={quoteRouteSegmentIdEdit}
                        quoteId={quoteId}
                        onSaved={() => {
                            toast.success('Opción de tramo Actualizada');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Información opción de ruta"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentShowForm quoteRouteSegmentShow={quoteRouteSegmentShow} />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default QuoteRouteSegmentsContainer;
