import React, { useEffect, useState } from 'react';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import {
    defaultTransportationOrderItemDataForm,
    defaultTransportationOrderItemEditDataForm,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Place } from '../../../app/models/Place';
import { Service } from '../../../app/models/Service';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import TransportationOrderItemForm from './forms/TransportationOrderItemForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import { Routes } from '../../../app/models/RouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';

interface Props {
    canBeEdited: boolean;
    transportationOrderId: number;
    transportationOrderItemId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemEdit = ({
    canBeEdited,
    transportationOrderId,
    transportationOrderItemId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        editTransportationOrderItem,
        updateTransportationOrderItem,
        fetchingEditTransportationOrderItem,
        fetchingUpdateTransportationOrderItem
    } = useTransportationOrderItemService();

    const {
        fetchingGetQuoteRouteSegment,
        fetchingShowRsToTransportationQuoteDetail,
        getQuoteRouteSegment,
        showRsToTransportationQuoteDetail
    } = useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    // const [transportationOrderItemForm, setTransportationOrderItemForm] =
    //     useState<TransportationOrderItemDataForm>({
    //         ...defaultTransportationOrderItemDataForm,
    //         transportation_order_id: transportationOrderId
    //     });

    const [transportationOrderItemForm, setTransportationOrderItemForm] =
        useState<TransportationOrderItemDataForm>({
            ...defaultTransportationOrderItemDataForm,
            ['transportation_order_id']: transportationOrderId,
            // ['currency_id']: currencyId,
            ['route_id']: 0
        });
    const [transportationOrderItemEditForm, setTransportationOrderitemEditForm] =
        useState<TransportationOrderItemDataForm>({
            ...defaultTransportationOrderItemEditDataForm,
            // ['currency_id']: currencyId,
            ['route_id']: 0,
            ['quantity']: 0,
            ['agreed_distance']: 0
        });

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [routes, setRoutes] = useState<Routes[]>([]);
    const [hasQuote, setHasQuote] = useState<boolean>(false);

    useEffect(() => {
        if (transportationOrderItemId) {
            edit();
        }
    }, [transportationOrderItemId]);

    // useEffect(() => {
    //     if (services.length) {
    //         setTransportationOrderItemForm({
    //             ...transportationOrderItemForm,
    //             service_id: services[0].id
    //         });
    //     }
    // }, [services]);

    useEffect(() => {
        if (!hasQuote) {
            console.log('no tiene quote');

            if (
                transportationOrderItemForm.route_id &&
                transportationOrderItemEditForm.route_id != transportationOrderItemForm.route_id
            ) {
                console.log('diferente id de ruta');
                showRsToTransportationQuoteDetail(transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderItemEditDataForm,
                            ...response.data.route_segment
                        });
                    }
                });
            } else if (
                transportationOrderItemForm.route_id &&
                transportationOrderItemEditForm.route_id == transportationOrderItemForm.route_id
            ) {
                console.log('mismo id de ruta');
                setErrorFields(undefined);
                console.log(transportationOrderItemEditForm);
                setTransportationOrderItemForm({
                    ...transportationOrderItemForm,
                    ...defaultTransportationOrderItemEditDataForm,
                    ...transportationOrderItemEditForm
                });
            }
        }
    }, [transportationOrderItemForm.route_id]);

    const edit = () => {
        editTransportationOrderItem(transportationOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setServices(response.data.services);

                if (response.data.routes_data.has_quote) {
                    console.log('tiene quote route segment');
                    setRoutes(response.data.routes_data.routes);

                    setHasQuote(true);
                } else {
                    console.log('no tiene quote route segment');
                    setRoutes(response.data.routes_data.routes);
                }

                if (response.data.transportation_order_item.status_selected?.id === 'FINALIZADO') {
                    setDisabled(true);
                }
                setTransportationOrderItemForm(response.data.transportation_order_item);
                setTransportationOrderitemEditForm({
                    quantity: response.data.transportation_order_item.quantity,
                    rate: response.data.transportation_order_item.rate,
                    quantity_unit_id: response.data.transportation_order_item.quantity_unit_id,
                    currency_id: response.data.transportation_order_item.currency_id,
                    rate_unit_id: response.data.transportation_order_item.rate_unit_id,
                    agreed_distance: response.data.transportation_order_item.agreed_distance,
                    route_id: response.data.transportation_order_item.route_id
                });
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando detalle pedido...', 400);
        updateTransportationOrderItem(transportationOrderItemId, transportationOrderItemForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingEditTransportationOrderItem ? (
                        <TransportationOrderItemForm
                            products={products}
                            quantityUnits={quantityUnits}
                            currencies={currencies}
                            services={services}
                            transportationOrderItemForm={transportationOrderItemForm}
                            routes={routes}
                            hasQuote={hasQuote}
                            setTransportationOrderItemForm={setTransportationOrderItemForm}
                            errorFields={errorFields}
                            canBeEdited={canBeEdited}
                            disabledEdit={true}
                            disabled={disabled}
                            fetching={
                                fetchingGetQuoteRouteSegment ||
                                fetchingShowRsToTransportationQuoteDetail
                            }
                        />
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemEdit;
