import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Provider, defaultProvider } from '../../../app/models/Provider';
import ProviderForm from '../components/ProviderForm';
import ProviderContactContainer from '../components/ProviderContactContainer';

import { Tab, Tabs } from 'react-bootstrap';
import BankAccountProviderContainer from '../components/BankAccountProviderContainer';
import { ProviderType } from '../../../app/models/ProviderType';
import { Society } from '../../../app/models/Society';
import { SocietyProviderDataForm } from '../../../app/models/SocietyProvider';
import { ProviderContact } from '../../../app/models/ProviderContact';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

const ProviderEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Proveedores',
            url: '/providers',
            isActive: false
        },
        {
            name: 'Editar Proveedores #' + id,
            url: '/providers/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editProvider,
        fetchingEditProvider,
        fetchingShowProvider,
        fetchingUpdateProvider,
        updateProvider,
    } = useProviderService();

   
    const [Provider, setProvider] = useState<Provider>(defaultProvider);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [societyProvider, setSocietyProvider] = useState<SocietyProviderDataForm>(
        {
            society_id: 0,
            provider_id: 0,
            account_number: ''
        }
    )


    const [errorFields, setErrorFields] = useState<any>();
    const [ProviderContacts, setProviderContacts] = useState<ProviderContact[]>([]);
    
    const providerIdSelected = useRef<number | undefined>(0);
    const {
        fetchingGetBusinessNameContactsByProvider,
        getBusinessNameContactByProvider
    } = useBusinessNameContactService()

    const [societyProviderFirst, setSocietyProviderFirst] = useState<SocietyProviderDataForm>(
        {
            provider_id: 0,
            account_number: ''
        }
    )


    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editProvider(id, {
            onSuccess: (response: ServiceResponse) => {
                setProviderTypes(response.data.provider_types);
                setProvider(response.data.provider);
                setProviders(response.data.providers)
                setSocieties(response.data.societies)
                setProviderContacts(response.data.provider_contacts);
                providerIdSelected.current = response.data.provider.provider_id;
                setSocietyProvider(response.data.society_provider);


                
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/providers');
               
                }
            }
        });

    };


    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (Provider != null) {
            if (showLoading) showLoading('loading', 'Actualizando Providere...');
            updateProvider(Number(Provider?.id), Provider, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
              
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };


    // useEffect(() => {
    
    //         if (Provider.provider_id == undefined) {
    //             providerIdSelected.current = undefined;
    //         }
    
    //         if (societyProvider.provider_id && Provider.provider_id != providerIdSelected.current) {
    
    //             getBusinessNameContactByProvider(societyProvider.provider_id, {
    //                     onSuccess: (response: ServiceResponse) => {
    
    //                         providerIdSelected.current = societyProvider.provider_id ?? 0;
    //                         setErrorFields(undefined);
    
    //                         setProviderContacts(response.data.provider_contacts);
    
    //                         if(societyProvider.provider_id == societyProviderFirst.provider_id){
    
    //                             setSocietyProvider(
    //                                 {
    //                                     ...societyProvider,
    //                                     business_name_contact_id: societyProviderFirst.business_name_contact_id ?? undefined,
    //                                     account_number: societyProviderFirst.account_number
    //                                 }
    //                             )
    
    //                         }else{
    //                             setSocietyProvider(
    //                                 {
    //                                     ...societyProvider,
    //                                     business_name_contact_id: undefined
    //                                 }
    //                             )
    //                         }
                            
                           
    //                     },
    //                     onError: (response: ServiceResponse) => {
    //                         setSocietyProvider({
    //                             ...societyProvider,
    //                             provider_id: providerIdSelected.current
    //                         });
    //                     }
    //                 })
                    
    //             }
    //     }, [Provider.provider_id]);


    return (
        <>
            <Breadcrumbs pageSection={`Editar proveedor #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditProvider ? (
                            <>
                                <ProviderForm
                                    societies={societies}
                                    providers={providers}
                                    societyProviderForm={societyProvider}
                                    setSocietyProviderForm={setSocietyProvider}
                                    providerTypes={providerTypes}
                                    providerContacts={ProviderContacts}
                                    loadingProviderContact={fetchingGetBusinessNameContactsByProvider}
                                    errorFields={errorFields} 
                                    isEdit = {true}
                                />
                                {/* <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateProvider}
                                        />
                                    </div>
                                </div> */}
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
            <Tabs defaultActiveKey="bank-accounts" id="bank-accounts" className="mb-3">
                <Tab eventKey="bank-accounts" title="Cuentas bancarias">
                    <BankAccountProviderContainer providerId={parseInt(id)} />
                </Tab>
                <Tab eventKey="provider-contacts" title="Contactos">
                    <ProviderContactContainer
                        providerId={parseInt(id)}
                    />
                </Tab>
            </Tabs>
          
      
        </>
    );
};

export default ProviderEdit;
