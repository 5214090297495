import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useClientService from '../../../app/services/hooks/useClientService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Client, defaultClient } from '../../../app/models/Client';
import ClientForm from '../components/ClientForm';
import ClientContactContainer from '../components/ClientContactContainer';
import { Tab, Tabs } from 'react-bootstrap';
import BankAccountClientContainer from '../components/BankAccountClientContainer';
import { Society } from '../../../app/models/Society';
import { ClientContact } from '../../../app/models/ClientContact';
import { SocietyClientDataForm } from '../../../app/models/SocietyClient';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

const ClientEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Clientes',
            url: '/clients',
            isActive: false
        },
        {
            name: 'Editar cliente #' + id,
            url: '/clients/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editClient,
        fetchingEditClient,
        fetchingShowClient,
        fetchingUpdateClient,
        updateClient,
    } = useClientService();

   
    const [client, setClient] = useState<Client>(defaultClient);
    const [clients, setClients] = useState<Client[]>([]);
    const [societyClient, setSocietyClient] = useState<SocietyClientDataForm>(
            {
                society_id: 0,
                client_id: 0,
                account_number: ''
            }
    )

    const [ClientContacts, setClientContacts] = useState<ClientContact[]>([]);
        

    const [societies, setSocieties] = useState<Society[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const {
        fetchingGetBusinessNameContactsByClient,
        getBusinessNameContactByClient
    } = useBusinessNameContactService()

    const [societyClientFirst, setSocietyClientFirst] = useState<SocietyClientDataForm>(
        {
            client_id: 0,
            account_number: ''
        }
    )


    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editClient(id, {
            onSuccess: (response: ServiceResponse) => {
                setClient(response.data.client);
                setClients(response.data.clients);
                setSocieties(response.data.societies);
                setClientContacts(response.data.client_contacts);

                
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/clients');
               
                }

            }
        });

    };


    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (client != null) {
            if (showLoading) showLoading('loading', 'Actualizando cliente...');
            updateClient(Number(client?.id), client, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
              
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };


    return (
        <>
            <Breadcrumbs pageSection={`Editar Cliente #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditClient ? (
                            <>
                                <ClientForm
                                    societies={societies}
                                    societyClientForm={societyClient}
                                    setSocietyClientForm={setSocietyClient}
                                    loadingClientContact={fetchingGetBusinessNameContactsByClient}
                                    clients={clients}
                                    clientContacts={ClientContacts}
                                    errorFields={errorFields} 
                                />
                                {/* <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateClient}
                                        />
                                    </div>
                                </div> */}
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
            <Tabs defaultActiveKey="bank-accounts" id="bank-accounts" className="mb-3">
                <Tab eventKey="bank-accounts" title="Cuentas bancarias">
                    <BankAccountClientContainer clientId={parseInt(id)} />
                </Tab>
                <Tab eventKey="client-contacts" title="Contactos">
                    <ClientContactContainer
                        clientId={parseInt(id)}
                    />
                </Tab>
            </Tabs>
          
      
        </>
    );
};

export default ClientEdit;
