import React, { useContext } from 'react';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import {
    ServiceManifestDetailDataForm,
    serviceManifestDetailFilters
} from '../../../../app/models/ServiceManifestDetail';
import ServiceManifestDetailFilter from './ServiceManifestDetailFilter';

import { AccountingStatus } from '../../../../app/models/Accounting';
import AccountingChangeStatus from '../AccountingChangeStatus';
import { Link } from 'react-router-dom';
import { Items } from '../../../../app/shared/enums';

interface Props {
    serviceManifestDetails: ServiceManifestDetailDataForm[];
    totalRows: number;
    edit?: (purchaseOrderDetailId: number, position: number) => void;
    destroy?: (purchaseOrderDetailId: number, position: number) => void;
    show?: (purchaseOrderDetailId: number, position: number) => void;
    toAccount?: (purchaseOrderDetailId: number) => void;
    filter: serviceManifestDetailFilters;
    setFilter: (filter: serviceManifestDetailFilters) => void;
    loading: boolean;
    isShow?: boolean;
    statuses?: AccountingStatus[];
    callbackSuccess?: () => void;
}

const ServiceManifestDetailDataTable = ({
    serviceManifestDetails,
    edit,
    destroy,
    show,
    toAccount,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    statuses,
    isShow = false,
    callbackSuccess
}: Props) => {
    const columns = [
        // {
        //     name: 'id',
        //     selector: (row: ServiceManifestDetailDataForm) => row?.id,
        //     sortable: true,
        //     sortField: 'id'
        // },
        {
            name: 'Posición #',
            selector: (row: ServiceManifestDetailDataForm) => row?.position,
            sortable: true,
            sortField: 'position',
            width: '80px'
        },
        {
            name: 'Glosa',
            selector: (row: any) => row?.item,
            sortable: true,
            sortField: 'item',
            minWidth: '120px',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">{row.item}</span>
            )
        },
        {
            name: 'Tipo de item',
            selector: (row: any) => row?.item_type,
            sortable: true,
            sortField: 'item_type',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.item_type}
                </span>
            )
        },
        {
            name: 'Centro de costo',
            selector: (row: any) => row?.cost_center,
            sortable: true,
            sortField: 'cost_center',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.cost_center}
                </span>
            )
        },
        {
            name: 'Lote',
            selector: (row: any) => row?.batch,
            sortable: true,
            sortField: 'batch',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">
                    {row.batch ? (
                        <Link to={'/batches/' + row.batch + '/show'}>
                            <span className="text-primary">#{row.batch}</span>
                        </Link>
                    ) : (
                        ''
                    )}
                </span>
            )
        },
        {
            name: 'Precio Unitario',
            selector: (row: any) => row?.price,
            sortable: true,
            sortField: 'price',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row.price !== null &&
                            `${formatMoney(row.price ?? 0, row.currency_price)} ${
                                row.currency_price
                            } / ${row.unit_price}`}
                    </span>
                </>
            )
        },
        {
            name: 'Cantidad',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.quantity ?? 0)} {row.unit}
                </span>
            )
        },

        {
            name: 'Precio Neto',
            selector: (row: any) => row?.gross_total,
            sortable: false,
            sortField: 'gross_total',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.gross_total ?? 0)}</span>
            )
        },
        {
            name: 'Impuestos',
            selector: (row: any) => row?.taxes,
            sortable: false,
            sortField: 'taxes',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.taxes ?? 0)}</span>
            )
        },

        {
            name: 'Precio Total',
            selector: (row: any) => row?.tax_total,
            sortable: false,
            sortField: 'tax_total',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.tax_total ?? 0)}</span>
            )
        },

        // {
        //     name: 'creado',
        //     selector: (row: any) => row?.created_at,
        //     sortable: true,
        //     sortField: 'created_at',
        //     cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
        //         <span className="">{momentParseDate(row?.created_at)}</span>
        //     )
        // },

        {
            name: 'Contabilización',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            width: '150px',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <span className="">{row.status_text}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '180px',
            cell: (row: ServiceManifestDetailDataForm, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}

                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro'}
                            title={'Eliminar'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1, row.position ?? 0)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                    {toAccount && (
                        <ButtonTableAction
                            callbackFunction={() => toAccount(row.id ?? -1)}
                            classIcon={'mdi mdi-counter'}
                            colorIcon={`text-${
                                row.item_type == Items.PRODUCT ? 'primary' : 'success'
                            }`}
                            errorMessage={'No se puede editar este registro'}
                            title={`Contabilizar ${
                                row.item_type == Items.PRODUCT ? 'producto' : 'servicio'
                            }`}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ServiceManifestDetailFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={serviceManifestDetails}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ServiceManifestDetailDataTable;
