import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { CostCenterDataForm, CostCenterFilters } from '../../models/CostCenter';

const useCostCenterService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCostCenters, setFetchingGetCostCenters] = useState(false);
    const [fetchingShowCostCenter, setFetchingShowCostCenter] = useState(false);
    const [fetchingCreateCostCenter, setFetchingCreateCostCenter] = useState(false);
    const [fetchingStoreCostCenter, setFetchingStoreCostCenter] = useState(false);
    const [fetchingEditCostCenter, setFetchingEditCostCenter] = useState(false);
    const [fetchingUpdateCostCenter, setFetchingUpdateCostCenter] = useState(false);
    const [fetchingDeleteCostCenter, setFetchingDeleteCostCenter] = useState(false);
    const [fetchingActiveCostCenter, setFetchingActiveCostCenter] = useState(false);
    const [fetchingGetCostCenterWithPurchaseOrder, setFetchingGetCostCenterWithPurchaseOrder] = useState(false);

    const getCostCenters = (CostCenterFilters: CostCenterFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(CostCenterFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.COST_CENTER.GET_COST_CENTERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCostCenters
        });
    };

    const createCostCenter = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COST_CENTER.CREATE_COST_CENTER,
            setFetching: setFetchingCreateCostCenter
        });
    };

    const showCostCenter = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COST_CENTER.SHOW_COST_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingShowCostCenter
        });
    };

    const storeCostCenter = (costCenter: CostCenterDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COST_CENTER.STORE_COST_CENTER,
            body: costCenter,
            setFetching: setFetchingStoreCostCenter
        });
    };

    const editCostCenter = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COST_CENTER.EDIT_COST_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingEditCostCenter
        });
    };

    const updateCostCenter = (id: number, costCenter: CostCenterDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.COST_CENTER.UPDATE_COST_CENTER.replace(':id', id.toString()),
            body: costCenter,
            setFetching: setFetchingUpdateCostCenter
        });
    };

    const deleteCostCenter = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.COST_CENTER.DELETE_COST_CENTER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCostCenter
        });
    };

    const activeCostCenter = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COST_CENTER.ACTIVE_COST_CENTER.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveCostCenter
        });
    };

    const getCostCenterWithPurchaseOrder = (id: number, purchase_order_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COST_CENTER.GET_COST_CENTERS
            .replace(':id', id.toString())
            .replace(':purchase_order_id', purchase_order_id.toString()),
            setFetching: setFetchingGetCostCenterWithPurchaseOrder
        });
    };

    return {
        fetchingGetCostCenters,
        fetchingShowCostCenter,
        fetchingCreateCostCenter,
        fetchingStoreCostCenter,
        fetchingEditCostCenter,
        fetchingUpdateCostCenter,
        fetchingDeleteCostCenter,
        fetchingActiveCostCenter,
        fetchingGetCostCenterWithPurchaseOrder,
        getCostCenters,
        showCostCenter,
        createCostCenter,
        storeCostCenter,
        editCostCenter,
        updateCostCenter,
        deleteCostCenter,
        activeCostCenter,
        getCostCenterWithPurchaseOrder
    };
};

export default useCostCenterService;
