import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import {
    DispatchProgramItem,
    DispatchProgramItemFilters,
    DispatchProgramItemStatus
} from '../../../app/models/DispatchProgramItem';
import DefaultModal from '../../../components/default/DefaultModal';
import DispatchProgramItemCarrierGuideContainer from './DispatchProgramItemCarrierGuideContainer';
import { toast } from 'react-toastify';
import DispatchProgramItemEdit from './DispatchProgramItemEdit';
import DispatchProgramItemShow from './DispatchProgramItemShow';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import DispatchProgramDocumentUpload from './DispatchProgramDocumentUpload';
import MassiveLoadCreate from './MassiveLoadCreate';
import DispatchProgramItemDataTable from './tables/DispatchProgramItemDataTable';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ServiceManifestCreateOnDispatchProgram from './ServiceManifestCreateOnDispatchProgram';


interface Props {
    dispatchProgramId: number;
    transportationSubOrderItemId: number;
    statuses?: DispatchProgramItemStatus[];
    canBeEdited: boolean;
    isShow?: boolean;
    reloadModule?: () => void;
    callbackSuccess: () => void;
    reloadModuleInterlocutor: () => void;
    refresh: boolean;
    status: string;

}

const TransportationSubOrderItemsContainer = ({
    dispatchProgramId,
    transportationSubOrderItemId,
    canBeEdited,
    reloadModule,
    reloadModuleInterlocutor,
    callbackSuccess,
    isShow = false,
    refresh,
    status,
}: Props) => {
    const {
        fetchingGetDispatchProgramItemsByTransportationSubOrderItem,
        fetchingExcelLoadBulk,
        getAllDispatchProgramItemsByTransportationSubOrderItem,
        deleteDispatchProgramItem,
        getExcelAllDispatchProgramItemsByTransportationSubOrderItem,
        excelLoadBulk
    } = useDispatchProgramItemService();
    const [dispatchProgramItems, setDispatchProgramItems] = React.useState<DispatchProgramItem[]>(
        []
    );
    const [totalRows, setTotalRows] = useState<number>(0);

    const { id } = useParams<RouterParams>();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingModalDocument, setShowingModalDocument] = useState(false);
    const [showingMassiveLoad, setShowingMassiveLoad] = useState(false);

    const [dispatchProgramItemIdEdit, setDispatchProgramItemEdit] = useState<number>(-1);
    const [dispatchProgramItemStatus, setDispatchProgramItemStatus] = useState<string>('');

    const [dispatchProgramItemIdShow, setDispatchProgramItemShow] = useState<number>(-1);
    const [dispatchProgramItemIdSync, setDispatchProgramItemIdSync] = useState<number>(-1);
    const [showingSync, setShowingSync] = useState(false);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [showingGenerateServiceManifest, setShowingGenerateServiceManifest] = useState(false);

    const [filter, setFilter] = useState<DispatchProgramItemFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    // const [statuses, setStatuses] = useState<TransportationSubOrderItemStatus[]>([]);

    useEffect(() => {
        reloadTable();
    }, [filter, refresh]);

    // useEffect(() => {
    //     if (transportationSubOrderItemId) {
    //         reloadTable();
    //     }
    // }, [transportationSubOrderItemId]);

    // useEffect(() => {
    //     if (transportationSubOrderItems) {
    //         reloadTable();
    //     }
    // }, [transportationSubOrderItems]);

    const showSync = (dispatchProgramItemId: number) => {
        setShowingSync(true);
        setDispatchProgramItemIdSync(dispatchProgramItemId);
    };
    const hideSync = () => {
        setShowingSync(false);
        setDispatchProgramItemIdSync(-1);
    };

    const showEdit = (dispatchProgramItemId: number, status: string) => {
        setShowingEdit(true);
        setDispatchProgramItemEdit(dispatchProgramItemId);
        setDispatchProgramItemStatus(status);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setDispatchProgramItemStatus('');
        setDispatchProgramItemEdit(-1);
    };

    const showShow = (dispatchProgramItemId: number) => {
        setShowingShow(true);
        setDispatchProgramItemShow(dispatchProgramItemId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setDispatchProgramItemShow(-1);
    };

    const showMassiveLoad = () => {
        setShowingMassiveLoad(true);
    };

    const hideMassiveLoad = () => {
        setShowingMassiveLoad(false);
    };

    const reloadTransportationOrderItemTable = () => {
        if (callbackSuccess !== undefined) {
            callbackSuccess();
        }
    };

    const destroy = (dispatchProgramItemId: number) => {
        const _text = 'Está a punto de eliminar el item de despacho #' + dispatchProgramItemId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert(
                    `Eliminando item despacho ${dispatchProgramItemId}`,
                    500
                );
                deleteDispatchProgramItem(dispatchProgramItemId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: response.message
                            // text: 'Se ha eliminado el item de despacho #' + dispatchProgramItemId
                        });
                        reloadTransportationOrderItemTable();
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                            // text: 'No se ha podido el item de despacho #' + dispatchProgramItemId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El item de despacho no se ha eliminado.'
                });
            }
        });
    };

    const reloadTable = () => {
        getAllDispatchProgramItemsByTransportationSubOrderItem(
            filter,
            Number(id),
            transportationSubOrderItemId,
            {
                onSuccess: (response: ServiceResponse) => {
                    setDispatchProgramItems(response.data.dispatch_program_items);
                    setTotalRows(response.data.total_rows);
                }
            }
        );
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllDispatchProgramItemsByTransportationSubOrderItem(
            filter,
            Number(id),
            transportationSubOrderItemId,
            {
                onSuccess: (message: string) => {
                    if (changeAnimation) changeAnimation('downloadSuccess', message, true);
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                }
            }
        );
    };

    const hideModalDocument = () => {
        setShowingModalDocument(false);
    };

    const showCreateServicemanifest = () => {
        setShowingGenerateServiceManifest(true);
   };

    const hideCreateServicemanifest = () => {
        setShowingGenerateServiceManifest(false);
    };

    return (
        <>
            <DefaultCard>
                {isShow ? (
                    <DispatchProgramItemDataTable
                        dispatchProgramId={dispatchProgramId}
                        dispatchProgramItems={dispatchProgramItems}
                        callbackSuccess={reloadTable}
                        loading={fetchingGetDispatchProgramItemsByTransportationSubOrderItem}
                        canBeEdited={!isShow}
                        reloadModule={reloadModule!}
                        totalRows={totalRows}
                        setFilter={setFilter}
                        filter={filter}
                        downloadExcel={downloadExcel}
                        showMassiveLoad={showMassiveLoad}
                        setShowingModalDocument={setShowingModalDocument}
                        status={status}
                    />
                ) : (
                    <DispatchProgramItemDataTable
                        dispatchProgramId={dispatchProgramId}
                        dispatchProgramItems={dispatchProgramItems}
                        loading={fetchingGetDispatchProgramItemsByTransportationSubOrderItem}
                        totalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                        edit={showEdit}
                        show={showShow}
                        destroy={destroy}
                        syncDailyCarrier={showSync}
                        reloadModule={reloadModule!}
                        callbackSuccess={reloadTable}
                        canChangeStatus={true}
                        canBeEdited={!isShow}
                        downloadExcel={downloadExcel}
                        generateServiceManifest={showCreateServicemanifest}
                        showMassiveLoad={showMassiveLoad}
                        setShowingModalDocument={setShowingModalDocument}
                        status={status}

                    />
                )}
            </DefaultCard>
            {showingSync ? (
                <DefaultModal
                    show={showingSync}
                    handleClose={hideSync}
                    title="ASIGNAR TRANSPORTISTA Y GUIA DE DESPACHO"
                    backdrop={true}
                    showFooter={false}
                >
                    <DispatchProgramItemCarrierGuideContainer
                        dispatchProgramItemId={dispatchProgramItemIdSync}
                        reloadModuleInterlocutor={reloadModuleInterlocutor}
                        onSaved={() => {
                            reloadTransportationOrderItemTable();
                            reloadTable();
                            reloadModuleInterlocutor();
                            hideSync();
                        }}
                        onError={(message?: string) => {
                            toast.error(message);
                        }}
                        onCancel={hideSync}
                    />
                </DefaultModal>
            ) : null}
            {showingModalDocument ? (
                <DefaultModal
                    show={showingModalDocument}
                    handleClose={hideModalDocument}
                    title="SUBIR DOCUMENTO"
                    backdrop={true}
                    showFooter={false}
                >
                    <DispatchProgramDocumentUpload
                        dispatchProgramDocumentId={Number(id)}
                    ></DispatchProgramDocumentUpload>
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Detalle Despacho"
                    backdrop={true}
                    showFooter={false}
                >
                    <DispatchProgramItemEdit
                        canBeEdited={canBeEdited}
                        dispatchProgramItemId={dispatchProgramItemIdEdit}
                        dispatchProgramId={dispatchProgramItemIdEdit}
                        dispatchProgramItemStatus={dispatchProgramItemStatus}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onError={(message) => {
                            toast.error(message);
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Ver detalle del item"
                    backdrop={true}
                    showFooter={false}
                >
                    <DispatchProgramItemShow
                        dispatchProgramItemId={dispatchProgramItemIdShow}
                        onError={(message?: string) => {
                            hideShow();
                            toast.error(message ?? 'Error al obtener el item de transporte');
                        }}
                    />
                </DefaultModal>
            ) : null}

            {showingMassiveLoad ? (
                <DefaultModal
                    show={showingMassiveLoad}
                    handleClose={hideMassiveLoad}
                    title="Carga masiva"
                    backdrop={true}
                    showFooter={false}
                >
                    <MassiveLoadCreate
                        dispatch_program_id={Number(id)}
                        transportation_order_item_id={transportationSubOrderItemId}
                        onSaved={() => {
                            reloadTransportationOrderItemTable();
                            reloadTable();
                            if (reloadModule) reloadModule();
                            hideMassiveLoad();
                        }}
                        onCancel={hideMassiveLoad}
                        onError={hideMassiveLoad}
                    />
                </DefaultModal>
            ) : null}
            {showingGenerateServiceManifest ? (
                <DefaultModal
                    show={showingGenerateServiceManifest}
                    handleClose={hideCreateServicemanifest}
                    title="Crear hoja de entrada"
                    backdrop={true}
                    showFooter={false}
                    size='lg'
                >
                    <ServiceManifestCreateOnDispatchProgram
                        dispatch_program_id={Number(id)}
                        onSaved={() => {
                            // reloadTable();
                            // if (reloadModule) reloadModule();
                            // hideCreateServicemanifest();
                        }}
                        onCancel={hideCreateServicemanifest}
                        onError={hideCreateServicemanifest}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportationSubOrderItemsContainer;
