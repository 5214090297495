import React, { useEffect, useState } from 'react';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Carrier } from '../../../app/models/Carrier';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import { Guide } from '../../../app/models/DispatchProgramItem';
import DispatchProgramItemDailyCarrierForm from './forms/DispatchProgramItemDailyCarrierForm';
import DispatchProgramItemGuideForm from './forms/DispatchProgramItemGuideForm';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { Provider } from '../../../app/models/Provider';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { DailyCarrier } from '../../../app/models/DailyCarrier';

interface Props {
    dispatchProgramItemId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
    reloadModuleInterlocutor: () => void;

}

const DispatchProgramItemCarrierGuideContainer = ({
    dispatchProgramItemId,
    onSaved,
    onCancel,
    onError,
    reloadModuleInterlocutor,
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const {
        getDispatchProgramItemDailyCarriersAvailable,
        updateDispatchProgramCarrierGuide,
        fetchingGetDispatchProgramItemDailyCarriersAvailable,
        fetchingSyncDispatchProgramDailyCarriers,
        getDispatchProgramItemGuide,
        fetchingGetDispatchProgramItemGuide
    } = useDispatchProgramItemService();

    const [errorFields, setErrorFields] = useState<any>();
    const [dailyCarrier, setDailyCarrier] = useState<DailyCarrier>({});
    const [providers, setProviders] = useState<Provider[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHopper] = useState<Hopper[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    const [guide, setGuide] = useState<Guide>({});

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        getList();
    }, []);

    const reloadInterlocutorsTable = () => {
        if (reloadModuleInterlocutor !== undefined) {
            reloadModuleInterlocutor();
        }
    };

    const getList = () => {
        useSweetAlert().spinnerAlert();

        getDispatchProgramItemGuide(dispatchProgramItemId, {
            onSuccess: (response: ServiceResponse) => {
                setGuide(response.data);
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError(response.message);
            }
        });
        getDispatchProgramItemDailyCarriersAvailable(dispatchProgramItemId, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setDailyCarrier(response.data.daily_carrier_selected);
                setProviders(response.data.providers);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHopper(response.data.hoppers);
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError(response.message);
            }
        });
    };

    const update = () => {
        updateDispatchProgramCarrierGuide(
            {
                dispatch_program_item_id: dispatchProgramItemId,
                provider_id: dailyCarrier.provider_id,
                carrier_id: dailyCarrier.carrier_id,
                truck_id: dailyCarrier.truck_id,
                hopper_id: dailyCarrier.hopper_id,

                file: guide.file,
                folio: guide?.folio,
                total: guide.total,
                tara_total: guide.tara_total ?? undefined,
                actual_dispatch_date: guide.actual_dispatch_date ?? undefined
            },
            {
                onSuccess: (response: ServiceResponse) => {
                    toast.success(response.message);
                    if (onSaved) onSaved();
                    reloadModuleInterlocutor();
                    console.log("aquiiii",reloadInterlocutorsTable())
                },
                onError: (response: ServiceResponse) => {
                    if (onError) onError(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <DispatchProgramItemDailyCarrierForm
                dailyCarrier={dailyCarrier}
                setDailyCarrier={setDailyCarrier}
                providers={providers}
                carriers={carriers}
                trucks={trucks}
                hoppers={hoppers}
                errorFields={errorFields}
            />
            <DispatchProgramItemGuideForm
                guide={guide}
                setGuide={setGuide}
                errorFields={errorFields}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingSyncDispatchProgramDailyCarriers}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingSyncDispatchProgramDailyCarriers}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemCarrierGuideContainer;
