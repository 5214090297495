import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { ServiceEvents } from "../shared/interfaces";
import { EndPoints } from '../EndPoints';
import { GroupActionDataForm } from "../../models/Action";


const useActionService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [ fetchingGetActions, setFetchingGetActions ] = useState(false);
    const [ fetchingEditActionsByGroup, setFetchingEditActionsByGroup ] = useState(false);
    const [ fetchingUpdateActionsByGroup, setFetchingUpdateActionsByGroup ] = useState(false);


    const getActions = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACTIONS.GET_ACTIONS,
            setFetching: setFetchingGetActions
        });
    };

    const editActionsByGroup = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ACTIONS.EDIT_ACTIONS_BY_GROUP.replace(':id', id.toString()),
            setFetching: setFetchingEditActionsByGroup
        });
    };

    const updateActionsByGroup = (id: number, actions: GroupActionDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.ACTIONS.UPDATE_ACTIONS_BY_GROUP.replace(':id', id.toString()),
            body: actions,
            setFetching: setFetchingUpdateActionsByGroup
        });
    };

    return {
        fetchingGetActions,
        fetchingEditActionsByGroup,
        fetchingUpdateActionsByGroup,
        getActions,
        editActionsByGroup,
        updateActionsByGroup
    }

}

export default useActionService;