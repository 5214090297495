import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ProviderDataForm, ProviderFilters } from '../../models/Provider';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useProviderService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProviders, setFetchingGetProviders] = useState(false);
    const [fetchingShowProvider, setFetchingShowProvider] = useState(false);
    const [fetchingCreateProvider, setFetchingCreateProvider] = useState(false);
    const [fetchingStoreProvider, setFetchingStoreProvider] = useState(false);
    const [fetchingEditProvider, setFetchingEditProvider] = useState(false);
    const [fetchingUpdateProvider, setFetchingUpdateProvider] = useState(false);
    const [fetchingDeleteProvider, setFetchingDeleteProvider] = useState(false);
    const [fetchingActiveProvider, setFetchingActiveProvider] = useState(false);
    const [fetchingGetProvider, setFetchingGetProvider] = useState(false);
    const [fetchingGetProviderContact, setFetchingGetProviderContact] = useState(false);

    const [fetchingGetDailyCarrierProvidersOnDispatchProgram, setFetchingGetDailyCarrierProvidersOnDispatchProgram] = useState(false);
  
    const getProviders = (providerFilters: ProviderFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(providerFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PROVIDERS.GET_PROVIDERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetProviders
        });
    };

    const showProvider = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.SHOW_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingShowProvider
        });
    };
    const activeProvider = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.PROVIDERS.ACTIVE_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingActiveProvider
        });
    };
    const createProvider = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.CREATE_PROVIDER,
            setFetching: setFetchingCreateProvider
        });
    };

    const storeProvider = (provider: ProviderDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVIDERS.STORE_PROVIDER,
            body: provider,
            setFetching: setFetchingStoreProvider
        });
    };

    const editProvider = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.EDIT_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingEditProvider
        });
    };

    const updateProvider = (id: number, provider: ProviderDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PROVIDERS.UPDATE_PROVIDER.replace(':id', id.toString()),
            body: provider,
            setFetching: setFetchingUpdateProvider
        });
    };

    const deleteProvider = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PROVIDERS.DELETE_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProvider
        });
    };

    const getProvider = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.GET_PROVIDER.replace(':id', id.toString()),
            setFetching: setFetchingGetProvider
        });
    };


    const getProviderContact = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.GET_PROVIDER_CONTACT.replace(':id', id.toString()),
            setFetching: setFetchingGetProviderContact
        });
    };

    const getDailyCarrierProvidersOnDispatchProgram = (dispatch_program_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVIDERS.GET_DAILY_CARRIER_PROVIDERS_ON_DISPATCH_PROGRAM
            .replace(':dispatch_program_id', dispatch_program_id.toString()),
            setFetching: setFetchingGetDailyCarrierProvidersOnDispatchProgram
        });
    };

   

    return {
        fetchingGetProviders,
        fetchingShowProvider,
        fetchingCreateProvider,
        fetchingStoreProvider,
        fetchingEditProvider,
        fetchingUpdateProvider,
        fetchingDeleteProvider,
        fetchingActiveProvider,
        fetchingGetProvider,
        fetchingGetProviderContact,
        fetchingGetDailyCarrierProvidersOnDispatchProgram,
        getProviders,
        activeProvider,
        showProvider,
        createProvider,
        storeProvider,
        editProvider,
        updateProvider,
        deleteProvider,
        getProvider,
        getProviderContact,
        getDailyCarrierProvidersOnDispatchProgram,
    };
};

export default useProviderService;
