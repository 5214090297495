import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { PurchaseOrderDetailDataForm } from '../../../app/models/PurchaseOrderDetail';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import { Product } from '../../../app/models/Product';
import { Service } from '../../../app/models/Service';
import PurchaseOrderDetailForm from './forms/PurchaseOrderDetailForm';
import useProductService from '../../../app/services/hooks/useProductService';
import useServiceService from '../../../app/services/hooks/useServiceService';
import { Items } from '../../../app/shared/enums';
import { RouteSegment } from '../../../app/models/RouteSegment';
import { CostCenter } from '../../../app/models/CostCenter';
import useCostCenterService from '../../../app/services/hooks/useCostCenterService';

interface Props {
    purchaseOrderId: number;
    itemType: string;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PurchaseOrderDetailCreate = ({ onSaved, onCancel, onError, purchaseOrderId, itemType }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createPurchaseOrderDetail,
        storePurchaseOrderDetail,
        fetchingCreatePurchaseOrderDetail,
        fetchingStorePurchaseOrderDetail
    } = usePurchaseOrderDetailService();

    const {
        getProductWithPurchaseOrder,
        fetchingGetProductWithPurchaseOrder
    } = useProductService()

    const {
        getServiceWithPurchaseOrder,
        fetchingGetServiceWithPurchaseOrder
    } = useServiceService()

    const {
        getCostCenterWithPurchaseOrder,
        fetchingGetCostCenterWithPurchaseOrder
    } = useCostCenterService()

    const [purchaseOrderDetail, setPurchaseOrderDetail] = useState<PurchaseOrderDetailDataForm>({
        purchase_order_id: purchaseOrderId,
        item_type: itemType
    });

    const [products, setProducts] = useState<Product[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const productIdSelected = useRef<number | undefined>(0);
    const serviceIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createPurchaseOrderDetail(itemType, {
            onSuccess: (response: ServiceResponse) => {
         
                setProducts(response.data.products);
                setServices(response.data.services);
                setCostCenters(response.data.cost_centers)
                setRouteSegments(response.data.route_segments);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);


    useEffect(() => {
        if (purchaseOrderDetail.product_id == undefined) {
            productIdSelected.current = undefined;
        }
        if (purchaseOrderDetail.product_id && purchaseOrderDetail.product_id != productIdSelected.current) {
            getProductWithPurchaseOrder(purchaseOrderDetail.product_id, purchaseOrderId, {
                onSuccess: (response: ServiceResponse) => {
                    productIdSelected.current = purchaseOrderDetail.product_id ?? 0;
                    setErrorFields(undefined);
                    
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        price: response.data.product.price,
                        currency_price: response.data.product.currency_price,
                        unit_price: response.data.product.unit_price,
                        unit: response.data.product.unit,
                        json_tax: response.data.product.taxes
                    });
                },
                onError: (response: ServiceResponse) => {
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        product_id: productIdSelected.current
                    });
                }
            });
        }
    }, [purchaseOrderDetail.product_id]);


    useEffect(() => {
        if (purchaseOrderDetail.service_id == undefined) {
            serviceIdSelected.current = undefined;
        }
        if (purchaseOrderDetail.service_id && purchaseOrderDetail.service_id != serviceIdSelected.current) {
            getServiceWithPurchaseOrder(purchaseOrderDetail.service_id, purchaseOrderId, {
                onSuccess: (response: ServiceResponse) => {
                    serviceIdSelected.current = purchaseOrderDetail.service_id ?? 0;
                    setErrorFields(undefined);
                    
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        price: response.data.service.price,
                        currency_price: response.data.service.currency_price,
                        unit_price: response.data.service.unit_price,
                        unit: response.data.service.unit,
                        json_tax: response.data.service.taxes,
                        service_type: response.data.service.service_type ?? undefined,
                        route_segment_id: 0,
                        first_dispatch_date: ''
                    });
                },
                onError: (response: ServiceResponse) => {
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        service_id: serviceIdSelected.current
                    });
                }
            });
        }
    }, [purchaseOrderDetail.service_id]);

    

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando posición de orden de compra...');
        storePurchaseOrderDetail(purchaseOrderDetail, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreatePurchaseOrderDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <PurchaseOrderDetailForm
                        purchaseOrderDetailDataForm={purchaseOrderDetail}
                        setPurchaseOrderDetailDataForm={setPurchaseOrderDetail}
                        itemType={itemType}
                        products={products}
                        services={services}
                        costCenters={costCenters}
                        routeSegments={routeSegments}
                        errorFields={errorFields}
                        itemLoading={itemType== Items.PRODUCT ? 
                            fetchingGetProductWithPurchaseOrder: fetchingGetServiceWithPurchaseOrder
                        }
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStorePurchaseOrderDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStorePurchaseOrderDetail} />
                </div>
            </div>
        </>
    );
};

export default PurchaseOrderDetailCreate;
