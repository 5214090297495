import React, { useEffect, useState } from 'react';
import { Client } from '../../../app/models/Client';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import { Society } from '../../../app/models/Society';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
import { SocietyClientDataForm } from '../../../app/models/SocietyClient';
import { ClientContact } from '../../../app/models/ClientContact';

type Props = {
    clients: Client[];
    societies: Society[];
    errorFields?: any;
    societyClientForm: SocietyClientDataForm;
    setSocietyClientForm: (societyClientForm: SocietyClientDataForm) => void;
    clientContacts: ClientContact[];
    loadingClientContact: boolean;
    clientTypes?: any[];
    isEdit?: boolean;

};

const ClientForm = ({ clients, societies, societyClientForm, setSocietyClientForm, clientContacts, loadingClientContact, errorFields, isEdit }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    
    useEffect(() => {
       
        setErrors(errorFields);
    }, [errorFields]);


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name === 'rut') {
            setSocietyClientForm({ ...societyClientForm, rut: formatRut(value) });
        } else {
            setSocietyClientForm({ ...societyClientForm, [name]: value });
        }
    };


    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        
        if(name === 'client_id'){
            const selectedClient: any = clients.find(
                (c) => c.id === value
            );
       
            if (selectedClient) {
              
                obj.rut = selectedClient.rut ?? '';
                obj.name = selectedClient.name ?? '';
                obj.email = selectedClient.email ?? '';
                obj.phone_code = selectedClient.phone_code ?? '';
                obj.phone = selectedClient.phone ?? '';
                obj.address = selectedClient.address ?? '';
                obj.is_national = selectedClient.is_national ?? undefined;
            }
        }
      
        setSocietyClientForm({ ...societyClientForm, ...obj });
        
    };

    const [image, setImage] = useState(
        societyClientForm?.logo
            ? societyClientForm.logo
            : 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
    );


    return (
        <>
           <div className="col-md-12 text-center d-flex">
                            <div className="mx-auto mb-3">
                                <label htmlFor="logo">
                                    <img
                                        src={
                                            typeof image === 'string' && image !== ''
                                                ? image
                                                : window.URL.createObjectURL(image as Blob)
                                        }
                                        className="rounded-circle pointer bg-white"
                                        style={{
                                            width: '150px',
                                            height: '150px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </label>
                            </div>
                       
                        </div>
                        <div className="mb-2 col-md-12">
                            <label className="col-form-label">Sociedad</label>
                            <SelectTwo
                                name="society_id"
                                id="society_id"
                                inputValue={societyClientForm.society_id}
                                options={SelectTwoMapperOptions(societies)}
                                hasError={fieldHasError('society_id') !== ''}
                                onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                                placeholder="Seleccione una sociedad"
                                onFocus={() => onFocusRemove('society_id')}
                                // disabled={isEdit}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('society_id')}
                            </div>
                        </div>
                        <div className="mb-2 col-md-12">
                            <label className="col-form-label">Cliente</label>
                            <SelectTwo
                                name="client_id"
                                id="client_id"
                                inputValue={societyClientForm.client_id}
                                options={SelectTwoMapperOptions(clients)}
                                hasError={fieldHasError('society_id') !== ''}
                                onChange={(value: any) => handleChangeSelectTwo('client_id', value.value)}
                                placeholder="Seleccione una sociedad"
                                onFocus={() => onFocusRemove('client_id')}
                                disabled={isEdit}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('client_id')}
                            </div>
                        </div>

                        <div className="mb-2 row needs-validation">
                            <label className="col-form-label">Origen</label>
                            <div className="col-md-12">
                                <input
                                    name="is_national"
                                    id="is_national"
                                    type="text"
                                    className={`form-control ${fieldHasError('is_national')}`}
                                    value={
                                        societyClientForm.is_national === undefined || societyClientForm.is_national === null
                                            ? ''
                                            : societyClientForm.is_national
                                            ? 'Nacional'
                                            : 'Extranjero'
                                    }
                                    readOnly={true}
                                />
                            </div>
                    </div>

                    
                    {/* <div className="mb-2 row">
                            <label className="col-form-label">N° cuenta corriente</label>
                            <div className="col-md-12">
                                <input
                                    name="account_number"
                                    id="account_number"
                                    type="text"
                                    className={`form-control ${fieldHasError('account_number')}`}
                                    // value={Provider.account_number}
                                    value={societyClientForm.account_number}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('account_number')}
                                    placeholder="Ingrese n° de cuenta"
                                    readOnly={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('account_number')}
                                </div>
                            </div>
                        </div> */}
                       
                    <div className="mb-2 row needs-validation">
                        <label className="col-form-label">Nombre</label>
                        <div className="col-md-12">
                            <input
                                name="name"
                                id="name"
                                type="text"
                                className={`form-control ${fieldHasError('name')}`}
                                value={societyClientForm.name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('name')}
                                // placeholder="Ingrese el nombre del proveedor"
                                readOnly={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('name')}
                            </div>
                        </div>
                    </div>

                        <div className="mb-2 row">
                            <label className="col-form-label">Rut</label>
                            <div className="col-md-12">
                                <input
                                    name="rut"
                                    id="rut"
                                    type="text"
                                    className="form-control"
                                    value={societyClientForm.rut || ''}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('rut')}
                                    // placeholder="Ingrese el RUT del proveedor"
                                    readOnly={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rut')}
                                </div>
                            </div>
                        </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Email</label>
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={societyClientForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese email"
                        readOnly={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row"'>
                <div className="col-md-12">
                    <label className="col-form-label">Dirección</label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        value={societyClientForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        // placeholder="Ingrese domicilio"
                        readOnly={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>               
            </div>
            <div className='mb-2 row'>
                <div className="col-md-12">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={societyClientForm.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                                disabled={true}
                            >
                                <option value="">--</option>
                                <option value="+56">+56</option>
                            </select>
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_code')}
                            </div>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="text"
                                className="form-control"
                                value={societyClientForm.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                                readOnly={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientForm;
