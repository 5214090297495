import React from 'react';
import { Client, ClientFilters } from '../../../app/models/Client';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import ClientFilter from './ClientFilter';
import { SocietyClient } from '../../../app/models/SocietyClient';

interface Props {
    clients: Client[];
    totalRows: number;
    loading?: boolean;
    edit?: (clientId: number) => void;
    show?: (clientId: number) => void;
    destroy?: (clientId: number) => void;
    filter: ClientFilters;
    setFilter: (filter: ClientFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const ClientDataTable = ({
    clients,
    totalRows,
    loading = false,
    edit,
    show,
    destroy,
    filter,
    setFilter
}: Props) => {
    const columns = [
       {
                  name: 'ID',
                  selector: (row: any) => row.id,
                  sortable: true,
                  sortField: 'id',
                  cell: (row: Client, index: any, column: any) => (
                      <span className="">
                          {' '}
                          {/* Agrega la clase de Bootstrap que desees */}
                          {row.id}
                      </span>
                  )
              },
              {
                  name: 'Rut Sociedad',
                  selector: (row: any) => row.society_rut,
                  sortable: true,
                  sortField: 'society_rut',
                  cell: (row: SocietyClient, _index: any, column: any) => (
                      <span className="">
                          {' '}
                          {/* Agrega la clase de Bootstrap que desees */}
                          {row.society?.rut}
                      </span>
                  )
              },
              {
                  name: 'Nombre Sociedad',
                  selector: (row: any) => row.society_name,
                  sortable: true,
                  sortField: 'society_name',
                  cell: (row: SocietyClient, index: any, column: any) => (
                      <span className="">
                          {' '}
                          {/* Agrega la clase de Bootstrap que desees */}
                          {row.society?.name}
                      </span>
                  )
              },
              {
                  name: 'Rut Cliente',
                  selector: (row: any) => row.client_rut,
                  sortable: true,
                  sortField: 'client_rut',
                  cell: (row: SocietyClient, index: any, column: any) => (
                      <span className="">
                          {' '}
                          {/* Agrega la clase de Bootstrap que desees */}
                          {row.client?.rut}
                      </span>
                  )
              },
              {
                  name: 'Nombre Cliente',
                  selector: (row: any) => row.client_name,
                  sortable: true,
                  sortField: 'client_name',
                  cell: (row: SocietyClient, index: any, column: any) => (
                      <span className="">
                          {' '}
                          {/* Agrega la clase de Bootstrap que desees */}
                          {row.client?.name}
                      </span>
                  )
              },
        // {
        //     name: 'Acciones',
        //     selector: (row: any) => row.id,
        //     sortable: false,
        //     cell: (row: Client, index: any, column: any) => (
        //         <div className="">
        //             {edit && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => edit(row.id ?? -1)}
        //                     classIcon={'mdi mdi-pencil'}
        //                     colorIcon={'text-warning'}
        //                     errorMessage={'No se puede editar este registro.'}
        //                     title={'Editar'}
        //                 />
        //             )}
        //             {destroy && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => destroy(row.id ?? -1)}
        //                     classIcon={'mdi mdi-delete'}
        //                     colorIcon={'text-danger'}
        //                     errorMessage={'No se puede eliminar este registro.'}
        //                     title={'Eliminar'}
        //                 />
        //             )}
        //         </div>
        //     )
        // }
    ];

    return (

            <>
            <div className="row mt-3">
            <div className="col">
                <ClientFilter filter={filter} setFilter={setFilter} />
            </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={clients}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            </>
  
    );
};

export default ClientDataTable;
