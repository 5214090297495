import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { Items } from '../../../app/shared/enums';
import useServiceManifestDetailService from '../../../app/services/hooks/useServiceManifestDetailService';
import {
    ServiceManifestDetailDataForm,
    serviceManifestDetailFilters
} from '../../../app/models/ServiceManifestDetail';
import ServiceManifestDetailDataTable from './tables/ServiceManifestDetailDataTable';
import ServiceManifestDetailShow from './ServiceManifestDetailShow';
import ServiceManifestDetailCreate from './ServiceManifestDetailCreate';
import ServiceManifestDetailEdit from './ServiceManifestDetailEdit';
import ServiceManifestDetailToAccount from './ServiceManifestDetailToAccount';
import { AccountingStatus } from '../../../app/models/Accounting';

interface Props {
    serviceManifestId: number;
    isShow?: boolean;
    statuses?: AccountingStatus[];
    smdIdParam?: number | null;
    serviceManifestDetails: ServiceManifestDetailDataForm[];
    setServiceManifestDetails: (serviceManifestDetails: ServiceManifestDetailDataForm[]) => void;
    reloadDetail:() => void;
    filterDetail?: serviceManifestDetailFilters;
    setFilterDetail?: (filterDetail: serviceManifestDetailFilters) => void;
    totalRowsDetail?: number

}

const ServiceManifestDetailContainer = ({
    serviceManifestId,
    isShow = false,
    statuses,
    smdIdParam = null,
    serviceManifestDetails,
    setServiceManifestDetails,
    filterDetail,
    setFilterDetail,
    reloadDetail,
    totalRowsDetail,
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getServiceManifestDetailsByServiceManifest,
        fetchingGetServiceManifestDetailsByServiceManifest,
        deleteServiceManifestDetail,
        fetchingDeleteServiceManifestDetail
    } = useServiceManifestDetailService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingToAccount, setShowingToAccount] = useState(false);

    const [serviceManifestDetailIdEdit, setServiceManifestDetailIdEdit] = useState<number>(-1);
    const [serviceManifestDetailIdShow, setServiceManifestDetailIdShow] = useState<number>(-1);
    const [serviceManifestDetailIdToAccount, setServiceManifestDetailIdToAccount] =
        useState<number>(-1);
    const [position, setPosition] = useState<number>(0);
    // const [serviceManifestDetails, setServiceManifestDetails] = useState<
    //     ServiceManifestDetailDataForm[]
    // >([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [itemType, setItemType] = useState<string>('');

    const [filter, setFilter] = useState<serviceManifestDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });

    useEffect(() => {
        if (smdIdParam) {
            setServiceManifestDetailIdToAccount(smdIdParam);
            showToAccount(smdIdParam);
        }
    }, []);

    //reload del detalle se hara desde edit para reflejar cambios en los cambio de estado

    // useEffect(() => {
    //     reloadTable();
    // }, [filter]);

    // const reloadTable = () => {
    //     getServiceManifestDetailsByServiceManifest(filter, serviceManifestId, {
    //         onSuccess: (response: ServiceResponse) => {
    //             setServiceManifestDetails(response.data.service_manifest_details);
    //             setTotalRows(response.data.total_rows);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             toast.error(response.message);
    //         }
    //     });
    // };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (serviceManifestDetailId: number, position: number) => {
        setShowingEdit(true);
        setServiceManifestDetailIdEdit(serviceManifestDetailId);
        setPosition(position);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setServiceManifestDetailIdEdit(-1);
        setPosition(0);
    };

    const showShow = (serviceManifestDetailId: number, position: number) => {
        setShowingShow(true);
        setServiceManifestDetailIdShow(serviceManifestDetailId);
        setPosition(position);
    };

    const hideShow = () => {
        setShowingShow(false);
        setServiceManifestDetailIdShow(-1);
        setPosition(0);
    };

    const showToAccount = (serviceManifestDetailId: number) => {
        setShowingToAccount(true);
        setServiceManifestDetailIdToAccount(serviceManifestDetailId);
    };

    const hideToAccount = () => {
        setShowingToAccount(false);
        setServiceManifestDetailIdToAccount(-1);
        setPosition(0);
        setItemType('');
    };

    const destroy = (serviceManifestDetailId: number, position: number) => {
        const _text = 'Está a punto de eliminar la posición en la hoja de entrada #' + position;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando posición de la hoja de entrada...');
                        deleteServiceManifestDetail(serviceManifestDetailId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadDetail();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La posición en la hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    {!isShow && (
                        <div className="col-12 mb-2">
                            <div className="row">
                                <div className="col-auto">
                                    <ButtonCreate
                                        title="Nueva posición"
                                        callbackCreate={showCreate}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12">
                        {isShow ? (
                            // <ServiceManifestDetailDataTable
                            //     serviceManifestDetails={serviceManifestDetails}
                            //     loading={fetchingGetServiceManifestDetailsByServiceManifest}
                            //     totalRows={totalRows}
                            //     show={showShow}
                            //     toAccount={showToAccount}
                            //     filter={filter}
                            //     setFilter={setFilter}
                            //     isShow={true}
                            // />
                            null
                        ) : (
                            <ServiceManifestDetailDataTable
                                serviceManifestDetails={serviceManifestDetails}
                                edit={showEdit}
                                destroy={destroy}
                                show={showShow}
                                toAccount={showToAccount}
                                loading={fetchingGetServiceManifestDetailsByServiceManifest}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                statuses={statuses}
                                callbackSuccess={reloadDetail}
                            />
                        )}
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear posición"
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDetailCreate
                        serviceManifestId={serviceManifestId}
                        onSaved={() => {
                            reloadDetail();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Editar posición # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDetailEdit
                        serviceManifestDetailId={serviceManifestDetailIdEdit}
                        onSaved={() => {
                            reloadDetail();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title={`Ver posición # ${position}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDetailShow
                        serviceManifestDetailId={serviceManifestDetailIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}

            {showingToAccount ? (
                <DefaultModal
                    show={showingToAccount}
                    handleClose={hideToAccount}
                    title={
                        itemType === Items.PRODUCT
                            ? `Documento contable producto posición # ${position}`
                            : itemType === Items.SERVICE
                            ? `Documento contable servicio posición # ${position}`
                            : `Documento contable posición # ${position}`
                    }
                    backdrop={true}
                    showFooter={false}
                >
                    <ServiceManifestDetailToAccount
                        serviceManifestDetailId={serviceManifestDetailIdToAccount}
                        onCancel={hideToAccount}
                        onError={hideToAccount}
                        onSaved={reloadDetail}
                        itemType={itemType}
                        setItemType={setItemType}
                        isShow={isShow}
                        setPosition={setPosition}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ServiceManifestDetailContainer;
