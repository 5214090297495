import { Provider, ProviderFilters } from '../../../app/models/Provider';
import { Society } from '../../../app/models/Society';
import { SocietyProvider } from '../../../app/models/SocietyProvider';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import ProviderFilter from './ProviderFilter';

interface Props {
    providers: Provider[];
    totalRows: number;
    loading?: boolean;
    edit?: (providerId: number) => void;
    show?: (providerId: number) => void;
    destroy?: (providerId: number) => void;
    filter: ProviderFilters;
    setFilter: (filter: ProviderFilters) => void;
    showServiceManifest?: (dailyCarrierId: number) => void;
    paginationRowsPerPageOptions?: any[];
}

const ProviderDataTable = ({
    providers,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    showServiceManifest
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Provider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        {
            name: 'Rut Sociedad',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'society_rut',
            cell: (row: SocietyProvider, _index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.society?.rut}
                </span>
            )
        },
        {
            name: 'Nombre Sociedad',
            selector: (row: any) => row.society_name,
            sortable: true,
            sortField: 'society_name',
            cell: (row: SocietyProvider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.society?.name}
                </span>
            )
        },
        {
            name: 'Rut Proveedor',
            selector: (row: any) => row.provider_rut,
            sortable: true,
            sortField: 'provider_rut',
            cell: (row: SocietyProvider, index: any, column: any) => (
                <span className="">
                    {row.provider?.rut}
                </span>
            )
        },
        {
            name: 'Nombre Proveedor',
            selector: (row: any) => row.provider_name,
            sortable: true,
            sortField: 'provider_name',
            cell: (row: SocietyProvider, index: any, column: any) => (
                <span className="">
                    {row.provider?.name}
                </span>
            )
        },
        // {
        //     name: 'Acciones',
        //     selector: (row: any) => row.id,
        //     sortable: false,
        //     cell: (row: Provider, index: any, column: any) => (
        //         <div className="">
        //             {show && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => show(row.id ?? -1)}
        //                     classIcon={'mdi mdi-eye'}
        //                     colorIcon={'text-info'}
        //                     errorMessage={'No se puede mostrar este registro.'}
        //                     title={'Ver'}
        //                 />
        //             )}
        //             {edit && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => edit(row.id ?? -1)}
        //                     classIcon={'mdi mdi-pencil'}
        //                     colorIcon={'text-warning'}
        //                     errorMessage={'No se puede editar este registro.'}
        //                     title={'Editar'}
        //                 />
        //             )}
        //             {destroy && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => destroy(row.id ?? -1)}
        //                     classIcon={'mdi mdi-delete'}
        //                     colorIcon={'text-danger'}
        //                     errorMessage={'No se puede eliminar este registro.'}
        //                     title={'Eliminar'}
        //                 />
        //             )}
        //             {showServiceManifest && (
        //                 <ButtonTableAction
        //                     callbackFunction={() => showServiceManifest(row.id ?? -1)}
        //                     classIcon={'mdi mdi-eye'}
        //                     colorIcon={'text-success'}
        //                     errorMessage={'No se puede editar este registro.'}
        //                     title={'Ir a hoja de entrada'}
        //                 />
        //             )}
        //         </div>
        //     )
        // }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <ProviderFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={providers}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default ProviderDataTable;
