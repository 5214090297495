import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { CostCenterDataForm } from '../../../app/models/CostCenter';
import useCostCenterService from '../../../app/services/hooks/useCostCenterService';
import CostCenterForm from './forms/CostCenterForm';



interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CostCenterCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [costCenter, setcostCenter] = useState<CostCenterDataForm>({ name: '', description:''});
    
    const [errorFields, setErrorFields] = useState<any>();

    const { createCostCenter, fetchingCreateCostCenter ,fetchingStoreCostCenter, storeCostCenter } = useCostCenterService();


    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCostCenter({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

 
    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando centro de costo...');
        storeCostCenter(costCenter, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CostCenterForm
                        costCenterDataForm={costCenter}
                        setCostCenterDataForm={setcostCenter}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreCostCenter} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreCostCenter} />
                </div>
            </div>
        </>
    );
};

export default CostCenterCreate;
