import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { PurchaseOrderDetailDataForm } from '../../../app/models/PurchaseOrderDetail';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import PurchaseOrderDetailForm from './forms/PurchaseOrderDetailForm';
import { Product } from '../../../app/models/Product';
import { Service } from '../../../app/models/Service';
import useServiceService from '../../../app/services/hooks/useServiceService';
import useProductService from '../../../app/services/hooks/useProductService';
import { Items } from '../../../app/shared/enums';
import { RouteSegment } from '../../../app/models/RouteSegment';
import useCostCenterService from '../../../app/services/hooks/useCostCenterService';
import { CostCenter } from '../../../app/models/CostCenter';


interface Props {
    purchaseOrderId: number;
    purchaseOrderDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PurchaseOrderDetailEdit = ({ purchaseOrderId, purchaseOrderDetailId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditPurchaseOrderDetail,
        editPurchaseOrderDetail,
        fetchingUpdatePurchaseOrderDetail,
        updatePurchaseOrderDetail
    } = usePurchaseOrderDetailService();

    const {
        getProductWithPurchaseOrder,
        fetchingGetProductWithPurchaseOrder
    } = useProductService()

    const {
        getServiceWithPurchaseOrder,
        fetchingGetServiceWithPurchaseOrder
    } = useServiceService()

    const {
        getCostCenterWithPurchaseOrder,
        fetchingGetCostCenterWithPurchaseOrder
    } = useCostCenterService()

    const [products, setProducts] = useState<Product[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [purchaseOrderDetail, setPurchaseOrderDetail] = useState<PurchaseOrderDetailDataForm>({});
    const [firstEditPurchaseOrderDetail, setFirstEditPurchaseOrderDetail] = useState<PurchaseOrderDetailDataForm>({});

    const productIdSelected = useRef<number | undefined>(0);
    const serviceIdSelected = useRef<number | undefined>(0);
    const [itemType, setItemType] = useState<string>('');
    const [errorFields, setErrorFields] = useState<any>();
    const firstProductId = useRef<number>(0);
    const firstServiceId = useRef<number>(0);
    const firstQuantity = useRef<number>(0);
    const firstPrice = useRef<number>(0);
    const firstTotalServiceQuantity = useRef<number>(0);


    useEffect(() => {
        edit();
    }, []);

    

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando posición de orden de compra...');
        editPurchaseOrderDetail(purchaseOrderDetailId, {
            onSuccess: (response: ServiceResponse) => {

                setItemType(response.data.item_type);
           
                setProducts(response.data.products);
                setServices(response.data.services);
                setCostCenters(response.data.cost_centers);
                setRouteSegments(response.data.route_segments);
                if(response.data.purchase_order_detail.item_type == Items.SERVICE){
                    setItemType(response.data.purchase_order_detail.item_type);
                    serviceIdSelected.current = response.data.purchase_order_detail.service_id;
                    
                }
                else{
                    setItemType(response.data.purchase_order_detail.item_type);
                    productIdSelected.current = response.data.purchase_order_detail.product_id;
               

                }
                setPurchaseOrderDetail(response.data.purchase_order_detail);
                setFirstEditPurchaseOrderDetail(response.data.purchase_order_detail);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (purchaseOrderDetail.product_id == undefined) {
            productIdSelected.current = undefined;
        }
     
        if (purchaseOrderDetail.product_id && purchaseOrderDetail.product_id != productIdSelected.current) {
            getProductWithPurchaseOrder(purchaseOrderDetail.product_id, purchaseOrderId, {
                onSuccess: (response: ServiceResponse) => {
                    productIdSelected.current = purchaseOrderDetail.product_id ?? 0;
                    setErrorFields(undefined);
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        currency_price: response.data.product.currency_price,
                        unit_price: response.data.product.unit_price,
                        unit: response.data.product.unit,
                        price: firstEditPurchaseOrderDetail.product_id == purchaseOrderDetail.product_id? 
                            firstEditPurchaseOrderDetail.price : response.data.product.price,
                        quantity: firstEditPurchaseOrderDetail.product_id == purchaseOrderDetail.product_id? 
                            firstEditPurchaseOrderDetail.quantity : 0,
                        total_service_quantity: firstEditPurchaseOrderDetail.product_id == purchaseOrderDetail.product_id? 
                            firstEditPurchaseOrderDetail.total_service_quantity: 0
                    });
                },
                onError: (response: ServiceResponse) => {
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        product_id: productIdSelected.current
                    });
                }
            });
        }
    }, [purchaseOrderDetail.product_id]);


    useEffect(() => {
        if (purchaseOrderDetail.service_id == undefined) {
            serviceIdSelected.current = undefined;
        }
      
        if (purchaseOrderDetail.service_id && purchaseOrderDetail.service_id != serviceIdSelected.current) {
            getServiceWithPurchaseOrder(purchaseOrderDetail.service_id, purchaseOrderId, {
                onSuccess: (response: ServiceResponse) => {
                    serviceIdSelected.current = purchaseOrderDetail.service_id ?? 0;
                    setErrorFields(undefined);
                    
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        price: firstEditPurchaseOrderDetail.service_id == purchaseOrderDetail.service_id? 
                            firstEditPurchaseOrderDetail.price : response.data.service.price,
                        currency_price: response.data.service.currency_price,
                        unit_price: response.data.service.unit_price,
                        service_type: response.data.service.service_type ?? undefined,
                        route_segment_id: firstEditPurchaseOrderDetail.service_id === purchaseOrderDetail.service_id? 
                            firstEditPurchaseOrderDetail.route_segment_id: 0,
                        first_dispatch_date: firstEditPurchaseOrderDetail.service_id === purchaseOrderDetail.service_id? 
                            firstEditPurchaseOrderDetail.first_dispatch_date: ''
                    });
                },
                onError: (response: ServiceResponse) => {
                    setPurchaseOrderDetail({
                        ...purchaseOrderDetail,
                        service_id: serviceIdSelected.current
                    });
                }
            });
        }
    }, [purchaseOrderDetail.service_id]);


    const update = () => {
        
        if (showLoading) showLoading('loading', 'Actualizando posición de orden de compra...');
        updatePurchaseOrderDetail(purchaseOrderDetailId, purchaseOrderDetail, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
            
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
            
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
               
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditPurchaseOrderDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <PurchaseOrderDetailForm
                       purchaseOrderDetailDataForm={purchaseOrderDetail}
                       setPurchaseOrderDetailDataForm={setPurchaseOrderDetail}
                       itemType={itemType}
                       products={products}
                       services={services}
                       costCenters={costCenters}
                       routeSegments={routeSegments}
                       errorFields={errorFields}
                       itemLoading={itemType== Items.PRODUCT ? 
                        fetchingGetProductWithPurchaseOrder: fetchingGetServiceWithPurchaseOrder
                        }
                       is_update={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdatePurchaseOrderDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdatePurchaseOrderDetail} />
                </div>
            </div>
        </>
    );
};

export default PurchaseOrderDetailEdit;
