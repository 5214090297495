import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Provider } from '../../../../app/models/Provider';
import { Service } from '../../../../app/models/Service';

interface InterlocutorFormProps {
    interlocutorForm: any;
    setInterlocutorForm: (interlocutorForm: any) => void;
    providers: Provider[];
    services: Service[];
    errorFields?: any;
    disabled?: boolean;
}

const InterlocutorForm = ({
    interlocutorForm,
    setInterlocutorForm,
    providers,
    services,
    errorFields,
    disabled = false
}: InterlocutorFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        if (errorFields) {
            setErrors(errorFields);
        }
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setInterlocutorForm({ ...interlocutorForm, [name]: value });
    };

    if (!interlocutorForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Proveedor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={interlocutorForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder="Seleccione un proveedor"
                        onFocus={() => onFocusRemove('provider_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Servicio</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="service_id"
                        id="service_id"
                        inputValue={interlocutorForm.service_id}
                        options={SelectTwoMapperOptions(services, 'id', ['name'])}
                        hasError={fieldHasError('service_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('service_id', value.value)}
                        placeholder="Seleccione un servicio"
                        onFocus={() => onFocusRemove('service_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('service_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InterlocutorForm;
