import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { Tax } from '../../../../app/models/Tax';
import LazyLoading from '../../../../components/LazyLoading';
import { PurchaseOrderDetailDataForm } from '../../../../app/models/PurchaseOrderDetail';
import { Product } from '../../../../app/models/Product';
import { Service } from '../../../../app/models/Service';
import { Currency } from '../../../../app/models/Currency';
import { Unit } from '../../../../app/models/Unit';
import { Items, ServiceType } from '../../../../app/shared/enums';
import { RouteSegment } from '../../../../app/models/RouteSegment';
import { CostCenter } from '../../../../app/models/CostCenter';

interface GeneralPurchaseOrderProps {
    purchaseOrderDetailDataForm: PurchaseOrderDetailDataForm;
    setPurchaseOrderDetailDataForm: (purchaseOrderDetail: PurchaseOrderDetailDataForm) => void;
    products: Product[];
    services: Service[];
    costCenters: CostCenter[];
    routeSegments: RouteSegment[];
    itemType: string;
    errorFields?: any;
    itemLoading: boolean;
    is_update?: boolean;
}

const PurchaseOrderDetailForm = ({
    purchaseOrderDetailDataForm,
    setPurchaseOrderDetailDataForm,
    products,
    services,
    costCenters,
    routeSegments,
    itemType,
    errorFields,
    itemLoading,
    is_update=false
}: GeneralPurchaseOrderProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setPurchaseOrderDetailDataForm({ ...purchaseOrderDetailDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setPurchaseOrderDetailDataForm({ ...purchaseOrderDetailDataForm, ...obj });
    };

 

    if (!purchaseOrderDetailDataForm) return null;

    return (
        <>

            {
                itemType == Items.PRODUCT?

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Producto</label>
                        <SelectTwo
                            name="product_id"
                            id="product_id"
                            inputValue={purchaseOrderDetailDataForm.product_id}
                            options={SelectTwoMapperOptions(products, 'id', ['name'])}
                            hasError={fieldHasError('product_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('product_id', value.value)
                            }
                            placeholder={'Seleccione producto'}
                            onFocus={() => onFocusRemove('product_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('product_id')}
                        </div>
                    </div>
                </div>
                :
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Servicio</label>
                        <SelectTwo
                            name="service_id"
                            id="service_id"
                            inputValue={purchaseOrderDetailDataForm.service_id}
                            options={SelectTwoMapperOptions(services, 'id', ['name'])}
                            hasError={fieldHasError('service_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('service_id', value.value)
                            }
                            placeholder={'Seleccione servicio'}
                            onFocus={() => onFocusRemove('service_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('service_id')}
                        </div>
                    </div>
                </div>
            }
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Fecha de entrega</label>
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('delivery_date')}`}
                        name="delivery_date"
                        id="delivery_date"
                        value={purchaseOrderDetailDataForm.delivery_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('delivery_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('delivery_date')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Centro de costo</label>
                        <SelectTwo
                            name="cost_center_id"
                            id="cost_center_id"
                            inputValue={purchaseOrderDetailDataForm.cost_center_id}
                            options={SelectTwoMapperOptions(costCenters, 'id', ['name'])}
                            hasError={fieldHasError('cost_center_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('cost_center_id', value.value)
                            }
                            placeholder={'Seleccione centro de costo'}
                            onFocus={() => onFocusRemove('cost_center_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('cost_center_id')}
                        </div>
                    </div>
            </div>
            
            
            {
                itemLoading?
                <LazyLoading height="300" />
                :
                <>
                <div className="mb-2 row">
                        <label className="col-md-12 col-form-label">Precio</label>
                        <div className="col-md-12">
                            <div className="row g-1">
                                <div className="col-6">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="price"
                                    id="price"
                                    value={purchaseOrderDetailDataForm.price}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('price')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rate')}
                                </div>
                                </div>

                                <div className="col-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="currency_price"
                                    id="currency_price"
                                    value={purchaseOrderDetailDataForm.currency_price}
                                    disabled={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('currency_price_id')}
                                </div>
                                </div>

                                <div className="col-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="unit_price"
                                    id="unit_price"
                                    value={purchaseOrderDetailDataForm.unit_price}
                                    disabled={true}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('unit_price_id')}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

            
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Cantidad {is_update && 
                <span className="text-info">
                (total repartido: {purchaseOrderDetailDataForm.total_service_quantity})
            </span>
                } 
                </label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="quantity"
                                id="quantity"
                                className={`form-control ${fieldHasError('quantity')}`}
                                value={purchaseOrderDetailDataForm.quantity}
                                onChange={handleChange}
                                placeholder="Ingrese cantidad"
                                onFocus={() => onFocusRemove('quantity')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity')}
                            </div>
                        </div>

                        <div className="col-6">
                            <input
                                type="text"
                                name="unit"
                                id="unit"
                                className="form-control"
                                value={purchaseOrderDetailDataForm.unit}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_id')}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {

                purchaseOrderDetailDataForm.service_type === ServiceType.TRANSPORT &&

                <>
                < hr/>
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Tramo</label>
                        <SelectTwo
                            name="route_segment_id"
                            id="route_segment_id"
                            inputValue={purchaseOrderDetailDataForm.route_segment_id}
                            options={SelectTwoMapperOptions(routeSegments, 'id', ['name'])}
                            hasError={fieldHasError('route_segment_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('route_segment_id', value.value)
                            }
                            placeholder={'Seleccione tramo'}
                            onFocus={() => onFocusRemove('route_segment_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('route_segment_id')}
                        </div>
                    </div>
                </div>
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                    <input
                        type="datetime-local"
                        className={`form-control ${fieldHasError('first_dispatch_date')}`}
                        name="first_dispatch_date"
                        id="first_dispatch_date"
                        value={
                            (purchaseOrderDetailDataForm.first_dispatch_date && purchaseOrderDetailDataForm.first_dispatch_date.length > 20)
                                ? purchaseOrderDetailDataForm.first_dispatch_date.substring(
                                      0,
                                      purchaseOrderDetailDataForm.first_dispatch_date.length - 6
                                  )
                                : purchaseOrderDetailDataForm.first_dispatch_date
                        }
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de despacho"
                        onFocus={() => onFocusRemove('first_dispatch_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_dispatch_date')}
                    </div>
                </div>
            </div>
                </>
            }

        </>
        }
            
        
           
        </>
    );
};

export default PurchaseOrderDetailForm;
