import React, { useContext, useEffect, useState } from 'react';
import { Provider } from '../../../app/models/Provider';
import { Service } from '../../../app/models/Service';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import InterlocutorForm from './forms/InterlocutorForm';
import LazyLoading from '../../../components/LazyLoading';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import { defaultInterlocutor, Interlocutor, InterlocutorDataForm } from '../../../app/models/Interlocutor';

import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
    dispatchProgramId: number
}

const InterlocutorCreate = ({ onSaved, onCancel, onError, dispatchProgramId }: Props) => {
    const {
        createInterlocutor,
        storeInterlocutor,
        fetchingCreateInterlocutor,
        fetchingStoreInterlocutor
    } = useInterlocutorService();
    const [errorFields, setErrorFields] = useState<any>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);


    const [interlocutorForm, setInterlocutorForm] = useState<InterlocutorDataForm>(defaultInterlocutor);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createInterlocutor(dispatchProgramId,{
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers);
                setServices(response.data.services);
            },

            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
                
        });
    };

    const store = () => {
        storeInterlocutor(dispatchProgramId,interlocutorForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                if (onSaved) onSaved();

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message

                });
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingCreateInterlocutor ? (
                        <InterlocutorForm
                            providers={providers}
                            services={services}
                            interlocutorForm={interlocutorForm}
                            setInterlocutorForm={setInterlocutorForm}
                            errorFields={errorFields}
                            // canBeEdited={canBeEdited}
                        />
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreInterlocutor}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreInterlocutor}
                    />
                </div>
            </div>
        </>
    );
};

export default InterlocutorCreate;
