import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';

import { ServiceManifestDataForm } from '../../../app/models/ServiceManifest';
import PurchaseOrderDetailOnDispatchProgramDataTable from './tables/PurchaseOrderDetailOnDispatchProgramDataTable';
import { PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../../app/models/PurchaseOrderDetail';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { toast } from 'react-toastify';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';

import { Tab, Tabs } from 'react-bootstrap';
import ServiceManifestForm from './forms/ServiceManifestForm';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useProviderService from '../../../app/services/hooks/useProviderService';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import { Provider, ProviderFilters } from '../../../app/models/Provider';
import { Service } from '../../../app/models/Service';
import InterlocutorOnDispatchProgramDataTable from '../InterlocutorOnDispatchProgramDataTable';
import { Interlocutor, InterlocutorFilters } from '../../../app/models/Interlocutor';

interface Props {
    dispatch_program_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestCreateOnDispatchProgram = ({
    dispatch_program_id,
    onSaved,
    onCancel,
    onError
}: Props) => {

    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const history = useHistory();
    const {
        fetchingGetPurchaseOrderDetailsFinished, 
        getPurchaseOrderDetailsFinished
    } = usePurchaseOrderDetailService();
    const {
        fetchingStoreServiceManifestOnDispatchProgram,
        storeServiceManifestOnDispatchProgram,
        fetchingDeleteServiceManifestByDispatchProgram,
        deleteServiceManifestByDispatchProgram,
        goServiceManifest,
        fetchingGoServiceManifest
    } = useServiceManifestService()
    
    const {
        getDailyCarrierProvidersOnDispatchProgram,
        fetchingGetDailyCarrierProvidersOnDispatchProgram
    } = useProviderService()

    const {
        getInterlocutorsWithServiceManifest,
        fetchingGetInterlocutorsWithServiceManifest,
    } = useInterlocutorService()

    const [providers, setproviders] = useState<Provider[]>([]);
    const [services, setservices] = useState<Service[]>([]);
    const [InterlocutorsWithServiceManifest, setInterlocutorsWithServiceManifest] = useState<Interlocutor[]>([]);
    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        {
            dispatch_program_id: dispatch_program_id,
            total_guide: 0,
            daily_carrier: ''
        }
    );
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [dailyCarrierProviderTotalRows, setDailyCarrierProviderTotalRows] = useState(0);
    const [productName, setProductName] = useState<string>('');

    
    
    const [errorFields, setErrorFields] = useState<any>();

    const [allServices, setAllServices] = useState<Service[]>([]); // Lista completa de servicios
    const [filteredServices, setFilteredServices] = useState<Service[]>([]); // Lista filtrada por proveedor
    // const hasServiceManifest = useRef<boolean>(false);

    const [filter, setFilter] = useState<PurchaseOrderDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        service_id: 0,
        provider_id: 0,
    });
    const [interlocutorFilter, setInterlocutorFilter] = useState<InterlocutorFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [rows, setRows] = useState<any[]>([]);

    const [toggleCleared, setToggleCleared] = useState<boolean>(false);

    const resetContextActions = () => {

        setToggleCleared(!toggleCleared);
     
    };

    useEffect(() => {

        reloadTable(false);

    }, [filter]);

    useEffect(() => {

        reloadDailyCarrierProviderTable();

    }, [interlocutorFilter]);

    useEffect(() => {
        getDataToForm();
    }, [])

    const resetForm = () => {
        setServiceManifest(
            {
                ...serviceManifest,
                provider_id: undefined,
                service_id: undefined,
                purchase_order_detail_id: undefined,
                total_guide: 0,
                issue_date: '',
            }
        )
    }


    const handleServiceChange = (serviceId: number | null) => {
        setFilter({
            ...filter,
            service_id: serviceId || 0, // Actualizar el filtro con el `service_id`
        });
    };

    const handleProviderChange = (providerId: number | null) => {
        if (providerId) {
            const selectedProvider = providers.find((provider) => provider.provider_id === providerId);
            if (selectedProvider && Array.isArray(selectedProvider.services)) {
                setFilteredServices(selectedProvider.services);
                setFilter({
                    ...filter,
                    provider_id: providerId || undefined, 
                });
            } else {
                setFilteredServices([]);
            }
        } else {
            setFilteredServices([]);
            setFilter({
                ...filter,
                provider_id:providerId || 0, // Asegurarse de que no sea `0` sino `null` cuando no haya un provider seleccionado
            });
        }
    };

    const getDataToForm = () => {
        getDailyCarrierProvidersOnDispatchProgram(dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                setproviders(response.data.providers_daily_carriers);
                setProductName(response.data.product);
                setFilteredServices([]);
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };


    const reloadTable = (resetFilter: boolean) => {
        getPurchaseOrderDetailsFinished(filter, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                
                setErrorFields(undefined);
                if(resetFilter){
                    resetForm();
                }
                else{
                    setServiceManifest(
                        {
                            ...serviceManifest,
                            purchase_order_detail_id: undefined,
                        }
                    )
                    
                }
                
                resetContextActions();
                
                setPurchaseOrderDetails(response.data.purchase_order_details);
                setTotalRows(response.data.total_rows);
           
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };

    const reloadDailyCarrierProviderTable = () => {
        getInterlocutorsWithServiceManifest(interlocutorFilter, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                
                setInterlocutorsWithServiceManifest(response.data.interlocutors);
                setDailyCarrierProviderTotalRows(response.data.total_rows);
               
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };

    const showServiceManifest = (interlocutor_id: number) => {

        if (showLoading) showLoading('loading', 'Buscando hoja de entrada...');
        goServiceManifest(interlocutor_id, {
            onSuccess: (response: ServiceResponse) => {

                if (hideLoading) hideLoading();

                navigationPage('/service-manifests/' + response.data.service_manifest_id + '/edit', true);

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Generando hoja de entrada...');
        storeServiceManifestOnDispatchProgram(serviceManifest, {
            onSuccess: (response: ServiceResponse) => {

                if (hideLoading) hideLoading();
                setErrorFields(undefined);
                // setServiceManifest(
                //     {
                //         ...serviceManifest,
                //         provider_id: undefined,
                //         service_id: undefined,
                //         purchase_order_detail_id: undefined,
                //         total_guide: 0,
                //         issue_date: '',
                //     }
                // )
                toast.success(response.message);
                reloadTable(true);
                getDataToForm();
                reloadDailyCarrierProviderTable();
                if (onSaved) onSaved();
                
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

   
    const destroy = () => {

        const _text = 'Está a punto de eliminar la hoja de entrada del transportista '+serviceManifest.daily_carrier

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando hoja de entrada...');
                        deleteServiceManifestByDispatchProgram(serviceManifest, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                setServiceManifest(
                                    {
                                        ...serviceManifest,
                                        provider_id: undefined,
                                        purchase_order_detail_id: undefined,
                                        total_guide: 0,
                                        issue_date: ''
                                    }
                                )
                                reloadTable(true);
                                getDataToForm();
                                reloadDailyCarrierProviderTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <div className="row mb-4">
                <div className="col-12">
                    <ServiceManifestForm
                        form={serviceManifest} 
                        setForm={setServiceManifest}
                        providers={providers}
                        services={filteredServices}
                        errorFields={errorFields} 
                        deleteServiceManifest={destroy}
                        onServiceChange={handleServiceChange}
                        onProviderChange={handleProviderChange}
                    />
                    <hr />
                </div>
            </div>
            <Tabs defaultActiveKey="generate-data" id="service-manifest-tabs" className="mt-3 mb-3">
                <Tab eventKey="generate-data" title="Generar hojas de entrada">
                <>
                    
                    <div className="row justify-content-between align-items-center">

                        <div className="col-auto">
                            <input
                                name="product"
                                id="product"
                                type="text"
                                className="form-control"
                                value={productName}
                                readOnly={true}
                            />
                        </div>
                        
                        <div className="col-auto mt-2">
                            <ButtonSaveForm title='Generar' callbackSave={store} locked={fetchingStoreServiceManifestOnDispatchProgram} />
                        </div>
                    </div>
                    <hr />
                    <PurchaseOrderDetailOnDispatchProgramDataTable
                        form= {serviceManifest}
                        setForm={setServiceManifest}
                        purchaseOrderDetails={purchaseOrderDetails}
                        loading={fetchingGetPurchaseOrderDetailsFinished}
                        totalRows={totalRows}
                        setFilter={setFilter}
                        filter={filter}
                        toggleCleared={toggleCleared}
                        resetContextActions={resetContextActions}
                    />
            
                </>  
                </Tab>
                <Tab eventKey="Interlocutores con hojas de entrada" title="Interlocutores con hojas de entrada">

                    <InterlocutorOnDispatchProgramDataTable
                        interlocutors={InterlocutorsWithServiceManifest}
                        loading={fetchingGetInterlocutorsWithServiceManifest}
                        totalRows={dailyCarrierProviderTotalRows}
                        setFilter={setInterlocutorFilter}
                        filter={interlocutorFilter}
                        showServiceManifest={showServiceManifest}
                    />
                </Tab>
           </Tabs>
        </>
    );
};

export default ServiceManifestCreateOnDispatchProgram;
