import React from 'react';
import { ClientFilters } from '../../../app/models/Client';

type Props = {
    filter: ClientFilters;
    setFilter: (filter: ClientFilters) => void;
};

const ClientFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* Filtro: Rut Sociedad */}
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="society_rut">
                        Rut Sociedad
                    </label>
                    <input
                        type="text"
                        id="society_rut"
                        name="society_rut"
                        className="form-control form-control-sm"
                        value={filter.society_rut}
                        onChange={handlerInputFilter}
                    />
                </div>
            </div>

            {/* Filtro: Sociedad */}
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="society_name">
                        Sociedad
                    </label>
                    <input
                        type="text"
                        id="society_name"
                        name="society_name"
                        className="form-control form-control-sm"
                        value={filter.society_name}
                        onChange={handlerInputFilter}
                    />
                </div>
            </div>

            {/* Filtro: Nombre Cliente */}
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="client_name">
                        Nombre Cliente
                    </label>
                    <input
                        type="text"
                        id="client_name"
                        name="client_name"
                        className="form-control form-control-sm"
                        value={filter.client_name}
                        onChange={handlerInputFilter}
                    />
                </div>
            </div>

            {/* Filtro: Rut Cliente */}
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="client_rut">
                        Rut de Cliente
                    </label>
                    <input
                        type="text"
                        id="client_rut"
                        name="client_rut"
                        className="form-control form-control-sm"
                        value={filter.client_rut}
                        onChange={handlerInputFilter}
                    />
                </div>
            </div>
        </div>)
            {/* <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control form-control-sm"
                        value={filter.email}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="name">
                        Razón Social
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control form-control-sm"
                        value={filter.name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div> */}
};

export default ClientFilter;
