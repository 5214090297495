import React, { useEffect, useMemo, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Accordion } from 'react-bootstrap';
import ToggleBox from '../../../../components/ToggleBox';
import { ActionCategory, Action, GroupActionDataForm } from '../../../../app/models/Action';


type Props = {
    errorFields?: any;
    categories: ActionCategory[];
    groupActions: Action[];
    setGroupActions: (action: Action[]) => void;
    setGroupActionDataForm:(groupActionDataForm: GroupActionDataForm) => void;
};

const GroupNotificationForm = ({ 
    errorFields,
    categories,
    groupActions,
    setGroupActions,
    setGroupActionDataForm
}: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [searchTerm, setSearchTerm] = useState('');

    const filterGroups = (actionCategory: ActionCategory) => {
  
        return actionCategory.name.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };


    const filteredActionCategories = useMemo(() => {

        if (!categories) return [];
        
        return categories.filter(filterGroups);
        
    }, [categories, searchTerm]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        setGroupActionDataForm({
            actions: groupActions.map(act => act.id)
        });
    }, [groupActions]);

    const isSelectedAction = (id: number) => {
        return !!groupActions.find((act) => act.id === id);
    };

    const setSelectedActions = (action: Action) => {
        const selection = groupActions.find((act) => act.id === action.id);

        if (!selection) {
            setGroupActions([
                ...groupActions,
                action
            ]);
        } else {
            setGroupActions([
                ...groupActions.filter((act) => act.id !== action.id)
            ]);
        }
    };


    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className="row">
                <div className="col-md-12">
                    <label htmlFor="name" className="form-label">
                        Acciones
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Buscar grupos de programas"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <Accordion>
                        {categories.length != 0 ? (
                            filteredActionCategories.length === 0 ? (
                                <div className="mt-2 text-danger fw-bold">
                                    No se encontraron coincidencias
                                </div>
                            ) : (
                                filteredActionCategories.map((group, index) => {
                                return (
                                    <Accordion.Item
                                        onFocus={() => onFocusRemove('permissions')}
                                        eventKey={`group${index}`}
                                        key={`group${index}`}
                                    >
                                        <Accordion.Header>
                                            <span className="text-uppercase">{group.name}</span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row pt-2">
                                                {group.actions &&
                                                    group.actions.map(
                                                        (action, index) => {
                                                            return (
                                                                <ToggleBox
                                                                    key={`permission${index}`}
                                                                    onClick={() => setSelectedActions(action)}
                                                                    isSelected={isSelectedAction(action.id)}
                                                                    title=""
                                                                    name={String(
                                                                        action.description ??
                                                                            ''
                                                                    )}
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            }))
                        ) : (
                            <div className="mt-2 text-danger fw-bold">
                                No hay programas disponibles
                            </div>
                        )}
                    </Accordion>
                </div>
            </div>
        </form>
    );
};

export default GroupNotificationForm;



