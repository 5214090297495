import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import useActionService from '../../../app/services/hooks/useActionService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import GroupNotificationFormContainer from './GroupNotificationFormContainer';
import { Action, ActionCategory, GroupActionDataForm, defaultGroupActionDataForm } from '../../../app/models/Action';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';


interface Props {
    groupId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GroupNotifications = ({ groupId, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditActionsByGroup, fetchingUpdateActionsByGroup, editActionsByGroup, updateActionsByGroup } = useActionService();
    const [errorFields, setErrorFields] = useState<any>();
    const [actionCategories, setActionCategories] = useState<ActionCategory[]>([]);
    const [groupActions, setGroupActions] = useState<Action[]>([]);
    const [groupActionDataForm, setGroupActionDataForm] = useState<GroupActionDataForm>(defaultGroupActionDataForm);


    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando rol...');
        editActionsByGroup(groupId, {
            onSuccess: (response: ServiceResponse) => {
                setActionCategories(response.data.actions);
                setGroupActions(response.data.group_action);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando rol...');
        updateActionsByGroup(groupId, groupActionDataForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };
    
    return fetchingEditActionsByGroup ? (
        <LazyLoading height="300" />
    ) : (
        <GroupNotificationFormContainer
            fetching={fetchingUpdateActionsByGroup}
            categories={actionCategories}
            action={update}
            cancel={cancel}
            errorFields={errorFields}
            groupActions={groupActions}  
            setGroupActions={setGroupActions} 
            setGroupActionDataForm={setGroupActionDataForm}     
        />
    );
    
}

export default GroupNotifications
