export interface Action {
    id: number;
    name: string;
    category: string;
    description: string;
    is_active?: boolean;
}

export interface ActionCategory {
    name: string;
    actions: Action[]
}

export interface GroupActionDataForm {
    id?: number;
    name?: string;
    actions: number[]
}

export const defaultGroupActionDataForm: GroupActionDataForm = {
    actions: []
};