import React from 'react';
import ClientForm from './ClientForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Client } from '../../../app/models/Client';
import { Society } from '../../../app/models/Society';
import { SocietyClientDataForm } from '../../../app/models/SocietyClient';
import { ClientContact } from '../../../app/models/ClientContact';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    societies: Society[];
    errorFields?: any;
    clients: Client[];
    
    societyClientForm: SocietyClientDataForm;
    setSocietyClientForm: (societyproviderForm: SocietyClientDataForm) => void;
    clientContacts: ClientContact[];
    loadingClientContact: boolean;
    clientTypes?: any[]; 
    isEdit?: boolean
}

const ClientFormContainer = ({
    fetching,
    action,
    cancel,
    clients,
    societies,
    societyClientForm,
    setSocietyClientForm,
    clientTypes,
    clientContacts,
    loadingClientContact,
    errorFields = null,
    isEdit,
    
    
}: Props) => {

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ClientForm 
                        societies={societies}
                        clientTypes={clientTypes}
                        societyClientForm={societyClientForm}
                        setSocietyClientForm={setSocietyClientForm}
                        loadingClientContact={loadingClientContact}
                        clients={clients}
                        clientContacts={clientContacts}
                        errorFields={errorFields} 
                        isEdit={isEdit}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ClientFormContainer;
